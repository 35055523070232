
/*=============================================
=            Contact Page Styles            =
=============================================*/

/*=============================================
=            Contact Page Container            =
=============================================*/
.Contact-Page-Container {
  padding-top: 20vh;
  padding-bottom: 20vh;
  height: 250vh;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(#003B55, #000C11);
  align-items: center;
  justify-content: center;
}
/*=====  End of Contact Page Container  ======*/


/*=============================================
=  (Left on Desktop) Contact Summary Section       =
=============================================*/

/*----------  Contact Summary Section Container  ----------*/
.Contact-Summary-Section {
  display: flex;
  width: 40%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  color: white;
  padding-top: 25vh;
  padding: 5vw;
}

/*----------  Contact Page Header  ----------*/
.Contact-Header {
  padding-top: 25vh;
  text-align: left;
  color: #B2D233;
  font-family: Avenir Next Bold;
  font-size: 3vw;
  text-transform: uppercase;
}
/*----------  Contact Page Summary Text  ----------*/
.Contact-Summary-Text {
  padding-top: 5vh;
  width: 80%;
  word-wrap: break-word;
  line-height: 30px;
  color: white;
  font-family: Avenir Next;
  font-size: medium;
}
/*----------  Contact Page Help Text  ----------*/
.Contact-Help {
  padding-top: 5vh;
  font-family: Michroma;
  text-transform: uppercase;
}
/*=====  End of Contact Summary Section  ======*/

/*=============================================
=  (Right on Desktop) Contact Page Form Section            =
=============================================*/
.Contact-Form {
  margin-top: 15vh;
  display: flex;
  padding: 5vw;
  padding-left: 10vw;
  padding-right: 10vw;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  background-color: white;
}
/*=====  End of Contact   ======*/

/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 1100px) {
  .Contact-Page-Container {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .Contact-Summary-Section {
    height: fit-content;
    background-color: transparent;
    border: none;
    padding: 0vw;
    width: 70vw;
  }
  .Contact-Summary-Text {
    width: 100%;
    font-size: 3.8vw;
  }
  .Contact-Header {
    font-size: 3.8vw;
    padding-top: 0;
  }
  .Contact-Form {
    width: fit-content;
    padding: 5%;
  }
}
/*=====  End of @media Queries  ======*/

