
/*=============================================
=            Home Hero Styles            =
=============================================*/

/*=============================================
=            Home Hero Container            =
=============================================*/
.Home-Hero-Container {
  max-width: 100vw;
  background-color: transparent;
  height: 100vh;
  width: 100%;
  padding: 0;
  background-color: #003B55;
}
/*=====  End of Home Hero Container  ======*/


/*=============================================
=            Home Hero Background Image            =
=============================================*/
.Home-Hero-Background-Image {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  object-fit: cover;
  object-position: center;
}
/*=====  End of Home Hero Background Image  ======*/


/*=============================================
=            Home Hero Heading            =
=============================================*/
.Home-Hero-Heading-Container {
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 5vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 7vh;
  background-color: transparent;
}
.Home-Hero-Heading {
  display: flex;
  flex-direction: column;
}
.Home-Hero-Heading-Block-1 {
  color: white;
  text-transform: uppercase;
  font-family: Avenir Next Bold; 
  font-weight: 700;
  font-size: 8vw;
  position: relative;
  justify-content: center;
  text-align: left;
  width: 25vw;
  background-color: transparent;
  line-height: 15vh;
}
.Home-Hero-Heading-Block-2 {
  color: white;
  text-transform: uppercase;
  font-family: Avenir Next Bold; 
  font-weight: 700;
  font-size: 8vw;
  position: relative;
  justify-content: center;
  text-align: left;
  width: 25vw;
  background-color: transparent;
  line-height: 15vh;
}
.Home-Hero-Heading-Block-3 {
  color: white;
  text-transform: uppercase;
  font-family: Avenir Next Bold; 
  font-weight: 700;
  font-size: 8vw;
  position: relative;
  justify-content: center;
  text-align: left;
  width: 25vw;
  background-color: transparent;
  line-height: 15vh;
}
.Home-Hero-Sub-Heading {
  color: white;
  text-transform: uppercase;
  font-family: Michroma;
  font-weight: 300;
  font-size: 1vw;
  position: relative;
  justify-content: center;
  letter-spacing: 1px;
  text-align: center;
  width: 40vw;
}
/*=====  End of Home Hero Heading  ======*/


/*=============================================
=            Home Hero Button            =
=============================================*/
.Home-Hero-Button {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #B2D233;
  color: #B2D233;
  will-change: auto;
}
.Home-Hero-Button:hover {
  border: 1px solid white;
  color: white;
  cursor: pointer;
}
/*=====  End of Home Hero Button  ======*/


/*=============================================
=            Home Hero Badges            =
=============================================*/

.Home-Hero-Badge-Left-1 {
  position: absolute;
  left: 15vw;
  top: 25vh;
  width: 15vw;
  height: 15vw;
  cursor: pointer;
}
.Home-Hero-Badge-Left-2 {
  position: absolute;
  transform: rotate(-20deg);
  left: 13vw;
  top: 45vh;
  width: 15vw;
  height: 15vw;
  cursor: pointer;
}

.Home-Hero-Badge-Right-1 {
  position: absolute;
  right: 13vw;
  top: 25vh;
  cursor: pointer;
}
.Home-Hero-Badge-Right-2 {
  position: absolute;
  right: 9vw;
  top: 45vh;
  cursor: pointer;
}
.Home-Hero-Badge-0,
.Home-Hero-Badge-1,
.Home-Hero-Badge-2 {
  width: 15vw;
  height: 15vw;
}
.Home-Hero-Badge-3 {
  width: 20vw;
  height: 16vw;
}
// .Home-Hero-Badge-0:hover,
// .Home-Hero-Badge-1:hover,
// .Home-Hero-Badge-2:hover {
//   transform: rotate(15deg);
// }
/*=====  End of Home Hero Badges  ======*/



/*=============================================
=            @media Queries            =
=============================================*/
// Large Screen Size Break Point
@media screen and (max-width: 905px) {
  .Home-Hero-Heading {
    background-color: transparent;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .Home-Hero-Heading-Block-1, .Home-Hero-Heading-Block-2, .Home-Hero-Heading-Block-3 {
    margin-left: -5vw;
    font-size: 10vw;
    height: 12vh;
    padding: auto;
    background-color: transparent;
  }
  .Home-Hero-Badge-Left-1 {
    top: 6vh;
    left: 35vw;
  }
  .Home-Hero-Badge-Left-2 {
    top: 10vh;
    left: 44vw;
    transform: rotate(25deg);
  }
  .Home-Hero-Badge-Right-1 {
    top: 80vh;
    left: 15vw;
  }
  .Home-Hero-Badge-0,
  .Home-Hero-Badge-1,
  .Home-Hero-Badge-2 {
    width: 15vw;
    height: 15vw;
  }
  .Home-Hero-Sub-Heading {
    font-size: 1vw;
  }
}
// Medium Break Point
@media screen and (max-width: 600px) {
  .Home-Hero-Background-Image {
    will-change: transform;
  }
  .Home-Hero-Heading {
    background-color: transparent;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .Home-Hero-Heading-Block-1, .Home-Hero-Heading-Block-2, .Home-Hero-Heading-Block-3 {
    margin-left: -20vw;
    font-size: 15vw;
    height: 8vh;
    padding: auto;
    background-color: transparent;
  }
  .Home-Hero-Badge-Left-1 {
    top: 11vh;
    left: 35vw;
  }
  .Home-Hero-Badge-Left-2 {
    top: 16vh;
    left: 44vw;
    transform: rotate(25deg);
  }
  .Home-Hero-Badge-Right-1 {
    top: 80vh;
    left: 15vw;
  }
  .Home-Hero-Badge-Right-2 {
    top: 80vh;
    right: 14vw;
  }
  .Home-Hero-Badge-0,
  .Home-Hero-Badge-1,
  .Home-Hero-Badge-2 {
    width: 25vw;
    height: 25vw;
  }
  .Home-Hero-Badge-3 {
    width: 32vw;
    height: 25vw;
  }
  .Home-Hero-Sub-Heading {
    font-size: 2.5vw;
  }
  .Home-Hero-Badge[rotatebadge = '1'] {
    animation: none;
  }
}
/*=====  End of @media Queries  ======*/





