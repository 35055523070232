.Error-Page-Container {
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #006885;
  align-items: flex-end;
  justify-content: flex-end;
}
.Error-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  height: 20vh;
  background-color: transparent;
  padding: 5vw;
}
.Error-Page-Text {
  color: white;
  font-family: Michroma;
  font-size: 1.5vw;
  animation: fadeIn 8s;
  will-change: auto;
}
.Error-Page-Button {
  padding: 10px;
  font-size: 1vw;
  padding-left: 5vw;
  padding-right: 5vw;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  will-change: auto;
  animation: fadeIn 20s;
}
.Network-Error-Page-Container {
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #006885;
  align-items: flex-end;
  justify-content: flex-end;
}
.Network-Error-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  height: 20vh;
  background-color: transparent;
  padding: 5vw;
}
.Network-Error-Page-Text {
  color: white;
  font-family: Michroma;
  font-size: 1.5vw;
  animation: fadeIn 2s;
  will-change: auto;
}
.Network-Error-Page-Button {
  padding: 10px;
  font-size: 1vw;
  padding-left: 5vw;
  padding-right: 5vw;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  will-change: auto;
  animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@media screen and (max-width: 500px) {
  .Error-Page-Text {
    font-size: 1em;
  }
  .Error-Page-Button {
    font-size: 5vw;
  }
}