.Loading-Page-Container {
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #006885;
  align-items: flex-end;
  justify-content: flex-end;
}
.Loading-Page-Text {
  color: white;
  font-family: Michroma;
  font-size: 1.5vw;
  padding: 5vw;
}