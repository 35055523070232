
/*=============================================
=            Courses Page Styles            =
=============================================*/


/*=============================================
=            Containers            =
=============================================*/
.Courses-Page-Container {
  height: fit-content;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.Courses-Page-Hero-Container {
  width: 70%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/*=====  End of Containers  ======*/


/*=============================================
=            Header            =
=============================================*/
.Courses-Page-Sub-Header {
  font-family: Michroma;
  color: #B2D233;
  font-size: 1.2vw;
  text-transform: uppercase;
  padding-bottom: 2vh;
}
.Courses-Page-Header {
  font-family: Avenir Next Bold;
  font-size: 3vw;
  color: #003B55;
  text-transform: uppercase;
}
/*=====  End of Header  ======*/


/*=============================================
=            Hero Hash Link Buttons            =
=============================================*/

.Courses-Hero-Button-Container {
  padding-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
}
.Courses-Hero-Button {
  border: 1px #006885 solid;
  color: #006885;
  padding: 1vw;
  font-family: Avenir Next bold;
  font-size: 0.8vw;
  text-transform: uppercase;
  cursor: pointer;
}
.Courses-Hero-Button:hover  {
  background-color: #006885;
  color: white;
}
/*=====  End of Hero Hash Link Buttons  ======*/


/*=============================================
=            Courses Body            =
=============================================*/
.Courses-Page-Body {
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 100vw;
}
/*=====  End of Courses Body  ======*/




/*=============================================
=            Body Section Containers           =
=============================================*/
.Courses-Entry-Section {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Courses-MSSC-Section {
  height: fit-content;
}
.Courses-Career-Section {
  height: fit-content;
}
.Courses-Advancement-Section {
  height: fit-content;
}
/*=====  End of Body Section Containers  ======*/


/*=============================================
=            Section Header            =
=============================================*/
.Section-Header-Row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  background-color: transparent;
  max-width: 100vw;
}
.Section-Break-Line {
  width: 30vw;
  height: 1px;
  background-color: #33333377;
}
.Courses-Section-Header {
  color:#B2D233;
  font-size: 3vw;
  font-family: Avenir Next Bold;
  text-transform: uppercase;
  text-align: center;
}
/*=====  End of Section Header ======*/



/*=============================================
=            Courses Grids            =
=============================================*/


.Courses-Grid {
  height: fit-content;
  align-items: center;
  max-width: 100vw;
  max-height: fit-content;
  padding: 5vw;
  background-color: transparent;
  align-items: center;
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  row-gap: 2em;
  column-gap: 0em;
}
.Courses-Advancement-Grid {
  display: flex;
  height: fit-content;
  align-items: center;
  max-width: 100vw;
  max-height: fit-content;
  padding: 5vw;
  background-color: transparent;
  align-items: center;
  justify-content: space-evenly;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  row-gap: 2em;
  column-gap: 0em;
}
/*=====  End of Courses Grids  ======*/


/*=============================================
=            Courses Cards            =
=============================================*/
/*----------  Courses Card Container  ----------*/
.Courses-Card {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 40% 60%;
  width: 18vw;
  height: 22vw;
  padding: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: transparent;
  transition: 0.3s;
  -webkit-transform: 0.3s;
  -moz-transform:  0.3s;
  -o-transform: 0.3s;
  -ms-transform: 0.3s;
}
/*----------  Courses Card Header Container  ----------*/
.Course-Card-Header-Container {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  padding: 8%;
  height: auto;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  background-color: transparent;
}
/*----------  Courses Card Header  ----------*/
.Course-Card-Header {
  letter-spacing: 1px;
  line-height: 20px;
  color: #333333;
  display: flex;
  position: relative;
  font-family: Avenir Next Bold;
  justify-content: flex-start;
  justify-self: center;
  align-items: flex-end;
  font-weight: 800;
  font-size: 115%;
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 100%;
  text-align: left;
  align-self: left;
  word-break: keep-all;
  z-index: 10;
  text-shadow: white 1px 1px 1px;
}
/*----------  Courses Card Sub Header  ----------*/
.Course-Card-Sub-Header {
  z-index: 15;
  font-family: Avenir Next;
  color: #006885;
  font-weight: 600;
  font-size: 80%;
}
/*----------  Courses Card Stats  ----------*/
.Course-Card-Stats {
  z-index: 10;
  font-family: Avenir Next;
  font-size: 0.8vw;
  font-weight: 500;
  color: #8E8E8E;
  padding-bottom: 1em;
}
/*----------  Courses Card Button  ----------*/
.Course-Card-Buttons {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
}
.Course-Card-Button {
  z-index: 10;
  display: flex;
  width: fit-content;
  border: 1px #003B55 solid;
  color: #003B55;
  padding: .5vw;
  padding-left: .7vw;
  padding-right: .7vw;
  font-family: Michroma;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.7vw;
  text-transform: uppercase;
  background-color: transparent;
  overflow-wrap: break-word;
  cursor: pointer;
}
.Course-Card-Button:hover  {
  background-color: #003B55;
  color: white;
}
/*----------  Courses Card Image  ----------*/
.Course-Card-Image {
  object-fit: cover;
  object-position: top;
  z-index: 5;
  grid-row: 1;
  grid-column: 1;
  width: 18vw;
  height: 100%;
  position: relative;
}
/*=====  End of Courses Cards  ======*/

/*=============================================
=            @media Queries            =
=============================================*/

@media screen and (max-width: 1200px) {
  .Courses-Page-Hero-Container {
    background-color: transparent;
    justify-content: flex-end;
    height: 35vh;
    width: 100%;
    padding-bottom: 10vh;
    display: flex;
  }
  .Courses-Page-Header {
    font-size: 2vw;
    width: 100%;
    background-color: transparent;
    text-align: center;
  }
  .Courses-Page-Sub-Header {
    font-size: 1vw;
  }
  .Courses-Hero-Button-Container {
    width: 90vw;
  }

  .Courses-Entry-Section {
    background-color: transparent;
  }
  .Courses-MSSC-Section {
    background-color: transparent;
  }
  .Courses-Career-Section {
    background-color: transparent;
  }
  .Courses-Advancement-Section {
    background-color: transparent;
  }
  .Courses-Grid {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding-bottom: 10vh;
  }
  .Courses-Advancement-Grid {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding-bottom: 10vh;
  }
  .Courses-Card {
    height: 40vh;
    width: 30vh;
  }

  .Course-Card-Sub-Header {
    font-size: 1vw;
  }
  .Course-Card-Stats {
    font-size: 1.5vw;
  }
  .Courses-Hero-Button {
    font-size: 2vw;
  }
  .Course-Card-Image {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .Courses-Page-Hero-Container {
    background-color: transparent;
    justify-content: flex-end;
    height: 35vh;
    width: 100%;
    padding-bottom: 10vh;
    display: flex;
  }
  .Courses-Page-Header {
    font-size: 7vw;
    width: 100%;
    background-color: transparent;
    text-align: center;
  }
  .Courses-Page-Sub-Header {
    font-size: 3vw;
  }
  .Courses-Hero-Button-Container {
    width: 90vw;
  }
  .Courses-Entry-Section {
    background-color: transparent;
  }
  .Courses-MSSC-Section {
    background-color: transparent;
  }
  .Courses-Career-Section {
    background-color: transparent;
  }
  .Courses-Advancement-Section {
    background-color: transparent;
  }
  .Courses-Grid {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding-bottom: 10vh;
  }
  .Courses-Advancement-Grid {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding-bottom: 10vh;
  }
  .Courses-Card {
    height: 42vh;
    width: 35vh;
  }
  .Course-Card-Sub-Header {
    font-size: 3vw;
  }
  .Course-Card-Stats {
    font-size: 2.5vw;
  }
  .Courses-Hero-Button {
    font-size: 2vw;
  }
  .Course-Card-Image {
    width: 100%;
  }
  .Course-Card-Button {
    font-size: 2vw;
    padding: 5px;
  }
  .Card-Header {
    font-size: 3vw;
    width: 85%;
    height: 100%;
    word-break: break-all;
    line-break: loose;
  }
}
/*=====  End of @media Queries  ======*/

