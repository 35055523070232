/*=============================================
=            ProgramPathways Styles            =
=============================================*/

/*----------  Main Styling  ----------*/

/*=============================================
=            Main Containers            =
=============================================*/

.Program-Path-Container {
  height: 103vh;
  width: 100%;
  max-width: 100vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.Program-Path-Content-Container {
  height: 700px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  will-change: auto;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Text            =
=============================================*/

.Program-Path-Heading {
  text-align: center;
  color: #006885;
  font-weight: normal;
  text-transform: uppercase;
  word-spacing: 0.6vw;
  font-size: 30px;
}
.Program-Path-Heading-2 {
  text-align: center;
  font-family: Avenir Next Bold;
  font-size: 64px;
}
.Program-Path-Text {
  text-align: center;
  font-size:medium;
  line-height: 1.95em;
  font-family: Avenir Next;
  width: 75%;
  align-self: center;
  color: #707070;
}

/*=====  End of Text  ======*/

/*=============================================
=            Arrow            =
=============================================*/

.Down-Arrow {
  align-self: center;
  font-size: 4vw;
  font-weight: 900;
  height: 4vw;
  width: 1vw;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  color: #00a8e5;
}

/*=====  End of Arrow  ======*/

/*=============================================
=            Keyframe Animations            =
=============================================*/

@keyframes fadeincontent {
  0% {
    width: 0vw;
    height: 0vw;
    opacity: 0;
  }
  50% {
    width: 35vw;
    height: 22vw;
    opacity: 0;
  }
  100% {
    width: 70vw;
    height: 44vw;
    opacity: 1;
  }
}
/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/

@media (max-width: 800px) {
  .Program-Path-Container {
    height: 120vw;
  }
  .Program-Path-Content-Container {
    height: 90vw;
    width: 90%;
  }
  .Program-Path-Content-Container[fadeincontent='1'] {
    animation: none;
  }
  .Program-Path-Heading {
    font-size: 3.6vw;
  }
  .Program-Path-Heading-2 {
    font-size: 7vw;
  }
  .Program-Path-Text {
    font-size: 3.2vw;
    line-height: 2.4em;
    width: 95%;
  }
  .Down-Arrow {
    align-self: center;
    font-size: 50px;
    font-weight: 900;
    height: 15vw;
    width: 10vw;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    color: #00a8e5;
  }
  .Shadow-Block-Reveal {
    display: none;
  }
}
@media (max-width: 700px) {
  .Program-Path-Text {
    font-size: 3.8vw;
    line-height: 2em;
  }
}
  @media only screen and (max-height: 880px) and (min-height: 880px) {
    .Program-Path-Container {
      height: 140vw;
      background: transparent;
    }
  .Program-Path-Content-Container {
    height: 85%;
    width: 90%;
  }
  }

/*=====  End of @Media Queries  ======*/