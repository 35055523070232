/*=============================================
=            About Jumbo Styles            =
=============================================*/

/*----------  Main Styles  ----------*/

/*=============================================
=            Main Containers            =
=============================================*/

.About-Jumbo-Container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-image: url('../../../assets/images/webp/aboutjumbophoto.webp');
  height: 60vw;
  width: 100%;
  max-width: 100vw;
  background-position: center left;
  background-size: cover;
  position: relative;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Text            =
=============================================*/

.About-Jumbo-Text-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80%;
  width: 55%;
  position: relative;
  overflow: hidden;
}
.About-Jumbo-Text-Pod {
  width: 80%;
  height: 28%;
  position: absolute;
  bottom: 3vw;
  right: 4vw;
  will-change: auto;
}
.About-Jumbo-Text {
  font-family: Avenir Next Bold;
  color: white;
  font-size: 3vw;
  text-shadow: 0 0 2px black;
}
.About-Jumbo-Text-Blue {
  font-family: Avenir Next Bold;
  color: #00a8e5;
  font-size: 3vw;
  text-shadow: 0 0 3px black;
}

/*=====  End of Text  ======*/

/*=============================================
=            Keyframe Animations            =
=============================================*/

@keyframes slideintext {
  from {
    right: -50vw;
  }
  to {
    right: 4vw;
  }
}
@keyframes colorchange {
  from {
    color: #b2d233;
  }
  to {
    color: #00a8e5;
  }
}
/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/

@media (max-width: 600px) {
  .About-Jumbo-Container {
    height: 90vw;
    width: 100%;
    max-width: 100vw;
    background-position: center;
    background-size: cover;
    position: relative;
  }
  .About-Jumbo-Text-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 95%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .About-Jumbo-Text {
    font-family: Avenir Next Bold;
    color: white;
    font-size: 6vw;
    line-height: 1.3em;
    text-shadow: 0 0 2px black;
  }
  .About-Jumbo-Text-Pod {
    width: 80%;
    height: 30%;
    position: absolute;
    bottom: 10vw;
    right: 10vw;
  }
  .About-Jumbo-Text-Blue {
    font-family: Avenir Next Bold;
    color: #00a8e5;
    font-size: 6vw;
    text-shadow: 0 0 3px black;
  }
}
/*=====  End of @Media Queries  ======*/
