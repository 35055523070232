

/*=============================================
=            Accordion Board Styles           =
=============================================*/


/*=============================================
=            Accordion Board            =
=============================================*/
.Accordion-Board {
  display: flex;
  flex-direction: column;
}
/*=====  End of Accordion Board  ======*/

/*=============================================
=            Accordion Board Container            =
=============================================*/
.Accordion-Board-Container {
  height: auto;
  max-width: 100vw;
  max-height: fit-content;
  padding: 10vw;
  background-color: transparent;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  row-gap: 1em;
  column-gap: 1em;
  will-change: auto;
}
.Accordion-Board-Container[animateaccordion = '1'] {
  animation: slideAccordion 1.5s;
}
/*=====  End of Accordion Board Container  ======*/


/*=============================================
=            Accordion Board Shelves            =
=============================================*/
.Accordion-Shelf-Container-Closed {
  top: 0%;
  z-index: 50;
  max-height: 15vh;
  display: flex;
}
.Accordion-Shelf-Container-Open {
  top: 0;
  z-index: 100;
  max-height: 100%;
  height: auto;
  grid-area: 'shelf';
}
/*=====  End of Accordion Board Shelves  ======*/



/*=============================================
=            Shelf Top            =
=============================================*/
.Accordion-Shelf-Top {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 15vh;
  box-shadow: 0px 0px 6px #00000029;
}
.Shelf-Title {
  padding: 1em;
  color: #006885;
  text-align: left;
  font-family: Avenir Next Bold;
  font-size: larger;
  width: 60%;
}
.Shelf-Count {
  padding: 1em;
  color: #333333;
  font-family: Avenir Next;
  font-size: medium;
  width: 40%;
}
.Shelf-Button {
  padding: 1em;
  font-family: Avenir Next Bold;
  font-size: 1.4vw;
  color: #333333;
  font-family: Avenir Next;
  width: 5%;
  text-align: center;
}
/*=====  End of Shelf Top  ======*/

/*=============================================
=            Shelf Bottom Details            =
=============================================*/
.Shelf-Details {
  font-size: 0.8vw;
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  position: relative;
  padding: 2em;
  color: #8E8E8E;
  width: 100% auto;
  list-style: none;
  background: #F5F5F5 0% 0% no-repeat padding-box;
}
/*=====  End of Shelf Bottom Details  ======*/

 /*=============================================
 =            Training Tools Section            =
 =============================================*/
 /*----------  Training Tools Header  ----------*/
.Training-Tools-Header {
  display: flex;
  font-size: 3em;
  color:#003B55;
  align-items: flex-end;
  text-transform: capitalize;
  font-family: Avenir Next Bold;
  background-color: transparent;
}
/*----------  Training Tools Button Section Container  ----------*/
.Call-To-Action-Button-Container {
  padding-top: 10vh;
  grid-column: 2;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
/*---------- Training Tools Call To Action Button ----------*/
.Call-To-Action-Button {
  width: 60%;
  height: fit-content;
  font-family: Michroma;
  text-align: center;
  font-size: x-large;
  letter-spacing: 2px;
  font-weight: 700;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: uppercase;
  background-color: #00a8e5;
  border: 1px solid #00a8e5;
  color: white
}
/*----------  Hover Effect For Button  ----------*/
.Call-To-Action-Button:hover {
  border: 1px solid #b2d233;
  background-color: #00a8e5;
  color: white;
  cursor: pointer;
  animation: inAndOutBig 1.5s infinite;
}
/*----------  Button Section  ----------*/
.Call-To-Action-Section {
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 10vw;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  will-change: auto;
}
/*----------  Button Section Scroll Animation ----------*/
.Call-To-Action-Section[animateaccordion = '1'] {
  animation: zoomOpenAccordion 1.5s;
}
 /*=====  End of Training Tools Section  ======*/
 
/*=============================================
=            Chart Disclaimer            =
=============================================*/
.Disclaimer {
  background-color: transparent;
  width: 50%;
  height: fit-content;
  font-family: Michroma;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 0.8vw;
  margin-top: -5vh;
  margin-bottom: 15vh;
}

/*=====  End of Chart Disclaimer  ======*/


/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes slideAccordion {
  from {
    width: 20%;
    max-height: 100%;
  }
  to {
    width: 80%;
    max-height: 100%;
  }
}
@keyframes zoomOpenAccordion {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes inAndOutBig {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
/*=====  End of @keyframe Animations  ======*/

/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 1200px) {
  .Accordion-Board-Container[animateaccordion = '1'] {
    animation: none;
  }
  .Accordion-Board-Container {
    display: flex;
    background-color: transparent;
    flex-direction: column;
    padding-bottom: 15vh;
  }
  .Call-To-Action-Section {
    background-color: transparent;
    font-size: 40%;
    padding-bottom: 25vw;
    column-gap: 1em;
  }
  .Training-Tools-Header {
    font-size: 3em;
  }
  .Call-To-Action-Section[animateaccordion = '1'] {
    animation: none;
  }
  .Call-To-Action-Button {
    animation: none;
    width: fit-content;
    font-size: 1.5em;
  }
  .Accordion-Shelf-Top {
    background-color: transparent;
  }
  .Shelf-Title {
    width: 30%;
    word-wrap: break-word;
    font-size: 3vw;
  }
  .Shelf-Count {
    font-size: 3vw;
  }
  .Shelf-Details {
    font-size: 3vw;
  }
  .Shelf-Count {
    width: 40%;
    word-wrap: break-word;
  }
  .Shelf-Button {
    font-size: 5vw;
  }
}
/*=====  End of @media Queries  ======*/







