/*=============================================
=            ProgramCareerPathways Styles            =
=============================================*/
/*=============================================
=            Main Containers            =
=============================================*/

.Program-Career {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  height: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-top: 10vw;
  padding-bottom: 10vw;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Ghost Block Reveal            =
=============================================*/

.Reveal-Block {
  width: 0vw;
  position: absolute;
  background-color: #ffffff;
  align-self: center;
  display: flex;
  height: 100%;
  z-index: 2000;
  right: 0;
}
.Reveal-Block[careeranimate='1'] {
  animation: revealcards 3s linear;
  
}

/*=====  End of Ghost Block Reveal  ======*/

/*=============================================
=            Text            =
=============================================*/

/*----------  Main Text Containers  ----------*/

.Program-Career-Text-Container {
  text-align: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3vw;
  will-change: opacity auto;
}
.Program-Career-Text-Container[careeranimate='1'] {
  animation: easeincareerheading 4s;
}

/*----------  Headings  ----------*/

.Program-Career-Heading-1 {
  font-size: 20px;
  font-weight: normal;
  color: #003b55;
}
.Program-Career-Heading-2 {
  font-size: 64px;
  font-family: Avenir Next Bold;
  font-weight: bold;
  color: #90d12a;
}

/*----------  Paragraph Text  ----------*/

.Program-Pathway-Details {
  text-align: center;
  font-size:medium;
  line-height: 1.9em;
  font-family: Avenir Next;
  width: 60%;
  color: #707070;
  align-self: center;
  padding-top: 2vw;
  padding-bottom: 3vw;
}

/*=====  End of Text  ======*/

/*=============================================
=            Cards            =
=============================================*/

/*----------  Card Main Containers  ----------*/

.Career-Card-Holder {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  row-gap: 1.3em;
  column-gap: 0.1em;
  flex-direction: row;
  width: 95vw;
  max-width: 100%;
  height: fit-content;
  align-self: center;
  justify-content: space-evenly;
  position: relative;
  align-items: center;
  overflow: hidden;
  padding-left: 2vw;
  padding-right: 2vw;
}
.Career-Card {
  box-shadow: 0px 0px 15px #00000012;
  width: 21vw;
  height: 28vw;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 1000;
}
.Career-Card-Content {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  width: 80%;
  height: 40%;
  z-index: 2;
  bottom: 2vw;
}
.Career-Card-Content-Container {
  position: absolute;
  width: 22vw;
  height: 28vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

/*----------  Card Button  ----------*/

.Career-Card-Button {
  padding: 5px;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #00a8e5;
  color: #00a8e5;
  width: 12vw;
  height: 2.7vw;
  font-size: 1.2vw;
  cursor: pointer;
}
.Career-Card-Button:hover {
  border: 1.5px solid #b2d233;
  color: #b2d233;
}

/*----------  Card Text  ----------*/

.Career-Card-Heading {
  font-family: Avenir Next Bold;
  color: #003b55;
  font-size: 1.5vw;
  max-width: 100%;
  line-height: 2.2vw;
  word-wrap: break-word;
}
.Career-Card-Text {
  font-family: Avenir, Book;
  color: #707070;
  font-size: 1vw;
}

/*----------  Card Image  ----------*/

.Career-Card-SVG {
  z-index: 1;
  width: 100%;
  height: 78%;
  top: -10px;
  position: relative;
  padding: 0;
}

/*=====  End of Cards  ======*/

/*=============================================
=            Keyframe Animations            =
=============================================*/

@keyframes easeincareerheading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes revealcards {
  from {
    width: 100vw;
  }
  to {
    width: 0vw;
  }
}

/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/

@media (max-width: 800px) {
  .Program-Career {
    width: 100%;
    height: auto;
  }
  .Career-Card-Holder {
    display: grid;
    flex-direction: none;
    grid-template-columns: 40vw 40vw;
    grid-template-rows: 58vw 58vw;
    width: 90vw;
    height: auto;
    padding-bottom: 3vw;
    align-self: center;
    justify-content: space-evenly;
    justify-self: center;
    overflow: hidden;
  }
  .Program-Career-Text-Container {
    width: 100vw;
    height: auto;
  }
  .Program-Career-Heading-1 {
    font-size: 4.3vw;
  }
  .Program-Career-Heading-2 {
    font-size: 7.3vw;
  }
  .Program-Pathway-Details {
    text-align: center;
    font-size: 2.8vw;
    line-height: 2em;
    font-family: Avenir Next;
    width: 70%;
    color: #707070;
    align-self: center;
    padding-top: 2vw;
  }
  .Career-Card {
    box-shadow: 0px 0px 11px #00000012;
    width: 40vw;
    height: 54vw;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
    position: relative;
  }
  .Career-Card-Content {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    width: 30vw;
    height: 22vw;
    top: 27vw;
    left: -4.6vw;
    z-index: 2;
  }
  .Career-Card-Heading {
    font-family: Avenir Next Bold;
    color: #003b55;
    font-size: 3.3vw;
    line-height: 4vw;
  }
  .Career-Card-SVG {
    z-index: 1;
    width: 40vw;
    height: 40vw;
    top: -3px;
    position: relative;
    padding: 0;
  }
  .Career-Card-Button {
    padding: 2px;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid #00a8e5;
    color: #00a8e5;
    width: 25vw;
    height: 6.3vw;
    font-size: 2vw;
    margin-top: 1em;
  }
}
@media (max-width: 700px){
    .Program-Pathway-Details {
    font-size: 3.8vw;
  }
}

/*=====  End of @Media Queries  ======*/
