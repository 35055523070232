

/*=============================================
=            Body Section Styles            =
=============================================*/


/*=============================================
=            ALL            =
=============================================*/
* {
  max-width: 100vw;
}
/*=====  End of ALL  ======*/


/*=============================================
=            Body Sections            =
=============================================*/
.Section-1 {
  width: 100%;
  height: fit-content;
  max-width: 100vw;
  position: relative;
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding-top: 8%;
  padding-bottom: 8%;
  padding-right: 0;
  z-index: 50;
}
.Section-2 {
  width: 100%;
  height: fit-content;
  max-width: 100vw;
  position: relative;
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  z-index: 50;
  padding-top: 8%;
  padding-bottom: 8%;
}
.Section-3 {
  width: 100%;
  height: fit-content;
  max-width: 100vw;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  z-index: 50;
  padding-top: 8%;
  padding-bottom: 8%;
}
/*=====  End of Body Sections  ======*/

/*=============================================
=            Section Columns            =
=============================================*/
// For Right Aligned Sections
// Empty Section for Decoration
.Section-1-Left {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  will-change: auto;
}
// Slide in Animation
.Section-1-Left[slideinsection = '1'] {
  animation-name: slideinsection;
  animation-duration: 2s;
  transition: ease-in 3s;
  -webkit-transform: ease-in 3s;
  -moz-transform:  ease-in 3s;
  -o-transform:  ease-in 3s;
  -ms-transform: ease-in 3s;
}
// Has Content
.Section-1-Right {
  // border: 2px dotted green;
  position: relative;
  padding: 0em;
  align-items: flex-start;
  justify-content: center;
  row-gap: 8vh;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
}
// -------------------------
// For Left Aligned Sections
// Has Content
.Section-2-Left {
  align-items: flex-start;
  padding-left: 12vw;
  justify-content: center;
  row-gap: 3vh;
  display: flex;
  flex-direction: column;
  margin-left: 0%;
  width: 60%;
  height: 100%;
  background-color: transparent;
  will-change: auto;
}
// Slide in Animation
.Section-2-Left[slideinsection = '1'] {
  animation-name: slideinsection;
  animation-duration: 2s;
  transition: ease-in 1s;
  -webkit-transform: ease-in 1s;
  -moz-transform:  ease-in 1s;
  -o-transform:  ease-in 1s;
  -ms-transform: ease-in 1s;
}
// Empty Section for Decoration
.Section-2-Right {
  align-items: center;
  justify-content: center;
  row-gap: 3vh;
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  background-color: transparent;
}
/*=====  End of Section Columns  ======*/

/*=============================================
=            Body Section Headers            =
=============================================*/
.Section-1-Header {
  font-size: 5vw;
  font-family: Avenir Next Bold;
  font-weight: 800;
  color: #B2D233;
}
.Section-2-Header {
  font-size: 5vw;
  font-family: Avenir Next Bold;
  font-weight: 800;
  color: #006885;
}
.Section-3-Header {
  margin-bottom: 0.4em;
  font-size: 5vw;
  font-family: Avenir Next Bold;
  font-weight: 800;
  color: #00A8E5;
  text-shadow: 0.3px 0.2px white;
}
/*=====  End of Body Section Headers  ======*/


/*=============================================
=            Body Section Paragraph            =
=============================================*/
.Section-Paragraph {
  line-height: 2em;
  font-family: Avenir Next;
  background-color: transparent;
  font-size: medium;
  width: 60%;
  color: #707070;
}
/*=====  End of Body Section Paragraph  ======*/


/*=============================================
=            Body Section Buttons            =
=============================================*/


.Learn-More-Button-Blue {
  padding: 10px;
  font-size: medium;
  padding-left: 5vw;
  padding-right: 5vw;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #00A8E5;
  color: #00A8E5;
  cursor: pointer;
}
.Learn-More-Button-Green {
  padding: 10px;
  font-size: 1vw;
  padding-left: 5vw;
  padding-right: 5vw;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #B2D233;
  color: #B2D233;
  cursor: pointer;
}
.Learn-More-Button-Blue:hover {
  border: 1px solid #B2D233;
  color: #B2D233;
  cursor: pointer;
}
.Learn-More-Button-Green:hover {
  border: 1px solid #00A8E5;
  color: #00A8E5;
  cursor: pointer;
}


/*=====  End of Body Section Buttons  ======*/





/*=============================================
=            @media Queries            =
=============================================*/

@media screen and (max-width: 1180px) { 
  .Section-1 {
    width: 100%;
    height: fit-content;
    padding-top: 10vh;
  }
  .Section-2 {
    width: 100%;
    height: fit-content;
    padding-top: 10vh;
  }
  .Section-3 {
    width: 100%;
    height: fit-content;
    padding-top: 10vh;
  }
}
// Main Screen Size Break
@media screen and (max-width: 700px) { 
  .Section-1-Right {
    width: 90%;
    display: flex;
    align-items: left;
  }
  .Section-2-Right {
    width: 90%;
    display: flex;
    align-items: left;
  }
  .Section-1-Left {
    width: 10%;
  }
  .Section-1-Left[slideinsection = '1'] {
    animation: none;
  }
  .Section-1-Header {
    font-size: 8vw;
    height: fit-content;
  }
  .Section-2-Header {
    font-size: 8vw;
    height: fit-content;
  }
  .Section-3-Header {
    font-size: 8vw;
    height: fit-content;
  }
  .Section-Paragraph {
    font-size: 3.8vw;
    width: 80%;
  }
  .Learn-More-Button-Blue {
    font-size: 2vw;
    padding-right: 8vw;
    padding-left: 8vw;
  }
  .Learn-More-Button-Green {
    font-size: 2vw;
    padding-right: 8vw;
    padding-left: 8vw;
  }
}

/*=====  End of @media Queries  ======*/

/*=============================================
=            @keyframe Animations            =
=============================================*/
 // Animations
@keyframes slideinsection {
  from {
    margin-left: -10%;
  }
  to {
    margin-left: 0%;
  }
}
/*=====  End of @keyframe Animations  ======*/




