
/*=============================================
=     Card Carousel Styles       =
=============================================*/
/*=============================================
=            Carousel            =
=============================================*/
.Carousel {
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  overflow: visible;
}
/*=====  End of Carousel  ======*/

/*=============================================
=            Green Carousel Highlight            =
=============================================*/
.Green-Carousel {
  position: relative;
  align-items: center;
}
/*=====  End of EV Career Highlight  ======*/


/*=============================================
=            Header            =
=============================================*/
.Carousel-Header {
  font-family: Michroma;
  color: #003B55;
  font-size: 1.2vw;
  text-transform: uppercase;
}
.Green-Carousel-Header {
  font-family: Michroma;
  color: #B2D233;
  font-size: 1.2vw;
  text-transform: uppercase;
}
/*=====  End of Header  ======*/
/*=============================================
=            Carousel Containers            =
=============================================*/
.Carousel-Container {
  padding-top: 1em;
  padding-bottom: 1em;
  height: fit-content;
  width: 58vw;
  overflow-x: scroll;
  background-color: transparent;
}
.Carousel-Container-Wide {
  padding-top: 1em;
  padding-bottom: 1em;
  height: fit-content;
  width: 80vw;
  overflow-x: scroll;
  background-color: transparent;
}
/*=====  End of Carousel Containers  ======*/
/*=============================================
=            Carousel Horizontal Scrolls      =
=============================================*/
.Carousel-Scroll {
  padding-right: 1vw;
  height: 100%;
  max-height: 100%;
  width: 130%;
  min-width: fit-content;
  column-gap: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  overflow-x: none;
  background-color: transparent;
  scrollbar-width: none;
}
.Carousel-Scroll:first-child {
  margin-left: .1vw;
}
.Carousel-Scroll-Wide {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow-x: none;
  background-color: transparent;
  scrollbar-width: none;
}
.Carousel-Scroll-Wide:first-child {
  margin-left: -1.5vw;
}
/*=====  End of Carousel Horizontal Scrolls  ======*/
/*=============================================
=            Carousel Cards                  =
=============================================*/
/*----------  Card Containers  ----------*/
.Carousel-Card {
  cursor: pointer;
  grid-area: card;
  display: grid;
  grid-template-columns: auto;
  width: 18vw;
  height: 22vw;
  padding: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: transparent;
  z-index: 1;
  transition: 0.3s;
  -webkit-transform: 0.3s;
  -moz-transform: 0.3s;
  -o-transform: 0.3s;
  -ms-transform: 0.3s;
}
.Carousel-Career-Card {
  grid-area: card;
  display: grid;
  grid-template-columns: auto;
  width: 18vw;
  height: 22vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: transparent;
  z-index: 1;
  transition: 0.3s;
  -webkit-transform: 0.3s;
  -moz-transform: 0.3s;
  -o-transform: 0.3s;
  -ms-transform: 0.3s;
}
.Carousel-Advanced-Card {
  grid-area: card;
  display: grid;
  grid-template-columns: auto;
  width: 18vw;
  height: 22vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: transparent;
  z-index: 1;
  transition: 0.3s;
  -webkit-transform:  0.3s;
  -moz-transform:  0.3s;
  -o-transform:  0.3s;
  -ms-transform:  0.3s;
}
/*----------  Card Image  ----------*/
.Card-Image {
  z-index: 50;
  top: 0;
  right: 0;
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
  justify-self: flex-end;
  align-self: flex-start;
  position: relative;
  background-color: transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  image-rendering: optimizeQuality;
  object-fit: cover;
}
/*----------  Card Header  ----------*/
.Card-Header {
  letter-spacing: -1.5px;
  line-height: 35px;
  grid-row: 1;
  grid-column: 1;
  color: #003B55;
  display: flex;
  position: relative;
  font-family: Avenir Next Bold;
  justify-content: center;
  align-items: flex-end;
  font-weight: 800;
  font-size: 1.4em;
  text-align: justify;
  width: 80%;
  height: 80%;
  max-width: 100%;
  text-align: left;
  align-self: center;
  justify-content: flex-start;
  word-break: keep-all;
  justify-self: center;
  z-index: 200;
  word-wrap: break-word;
  background-color: transparent;
  text-shadow: white 1px 1px 1px;
}
/*----------  Card Summary Section  ----------*/
.Card-Summary {
  font-size: 0.7vw;
  z-index: 50;
  color: #707070;
  position: relative;
  display: flex;
  grid-row: 1;
  grid-column: 1;
  height: 100%;
  width: 80%;
  align-self: flex-end;
  justify-self: center;
  align-items: flex-end;
  padding: 10px;
}
/*=====  End of Carousel Cards  ======*/
/*=============================================
=            @media Queries            =
=============================================*/
// Main Screen Size Break
@media screen and (max-width: 1150px) { 
  .Carousel {
    width: fit-content;
    background-color: transparent;
    overflow: hidden;
    padding: 2vw;
  }
  .Carousel-Container {
    width: 80vw;
    background-color: transparent;
    height: fit-content;
  }
  .Carousel-Container-Wide {
    max-width: 80vw;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
  }
  .Carousel-Scroll-Wide {
    padding-bottom: 5vh;
    min-width: 130vw;
    column-gap: 0vw;
  }
  .Carousel-Card {
    height: 40vw;
    width: 30vw;
  }
  .Carousel-Career-Card {
    height: 40vw;
    width: 30vw;
  }
  .Carousel-Advanced-Card {
    height: 40vw;
    width: 30vw;
  }
  .Card-Header {
    font-size: 2vw;
    width: 70%;
    padding-left: 0vw;
    background-color: transparent;
    line-height: 3vh;
    word-wrap: break-word;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .Carousel-Header {
    font-size: 3vw;
  }

}
/*=====  End of @media Queries  ======*/

