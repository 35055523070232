
/*=============================================
=            Pathway Hero Styles            =
=============================================*/


/*=============================================
=            Pathway Hero Container            =
=============================================*/
.Pathway-Hero-Container {
  max-width: 100vw;
  background-color: transparent;
  height: 100vh;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
/*=====  End of Pathway Hero Container  ======*/

/*=============================================
=            Pathway Hero Image            =
=============================================*/
.Pathway-Hero-Image {
  object-fit: cover;
  width: 30vw;
  height: 100%;
  position: absolute;
  object-position: center;
  will-change: auto;
}
.Pathway-Hero-Image[animatehero = '1'] {
  animation: slideImage 2s;
}
/*=====  End of Pathway Hero Image  ======*/


/*=============================================
=            Pathway Hero Header            =
=============================================*/

/*----------  Header Upper Section  ----------*/
.Pathway-Hero-Heading-Container {
  right: 0;
  gap: 3vh;
  display: flex;
  position: absolute;
  height: 100%;
  width: 60vw;
  padding-left: 10vw;
  padding-top: 5vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #003B55;
}
.Pathway-Hero-Heading {
  color: white;
  font-family: Avenir Next Bold; 
  font-weight: 700;
  font-size: 350%;
  position: relative;
  justify-content: center;
  text-align: left;
  word-wrap: break-word;
  width: fit-content;
  max-width: 55vw;
  background-color: transparent;
}
.Pathway-Hero-Sub-Heading {
  color: #B2D233;
  text-transform: uppercase;
  font-family: Michroma;
  font-weight: 300;
  font-size: large;
  position: relative;
  justify-content: center;
  word-spacing: .5vw;
  text-align: left;
  width: 70%;
  background-color: transparent;
}
/*----------  Header Lower Section  ----------*/
.Pathway-Hero-Jobs-Container {
  margin-top: 0vh;
  position: relative;
}
.Pathway-Hero-Jobs-Heading {
  color: #00A8E5;
  font-size: large;
  font-family: Michroma;
  text-transform: uppercase;
}
.Pathway-Hero-Jobs {
  padding-top: 1em;
  color: white;
  font-size: medium;
  width: 70%;
  line-height: 1.5em;
}
.Pathway-Hero-Download {
  z-index: 250;
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  will-change: auto;
}
.Pathway-Hero-Download[animatehero = '1'] {
  animation: zoomOpen 1.5s;
}
.Pathway-Hero-Download:hover {
  animation: inAndOut 1.5s infinite;
}
.Pathway-Hero-Download-Button {
  padding: 10px;
  font-family: Michroma;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid white;
  color: white;
}
.Pathway-Hero-Download-Button:hover {
  border: 1px solid #B2D233;
  color: #B2D233;
  cursor: pointer;
}
/*=====  End of Pathway Hero Header  ======*/


/*=============================================
=            Pathway Hero Summary            =
=============================================*/
.Pathway-Hero-Summary {
  color: white;
  font-family: Avenir Next;
  font-weight: 300;
  font-size: medium;
  position: relative;
  justify-content: center;
  text-align: left;
  line-height: 1.5em;
  width: 70%;
  background-color: transparent;
}

/*=====  End of Pathway Summary  ======*/



/*=============================================
=            Pathway Hero Button            =
=============================================*/
.Pathway-Hero-Button {
  padding: 10px;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #B2D233;
  color: #B2D233;
}
.Pathway-Hero-Button:hover {
  border: 1px solid white;
  color: white;
}
/*=====  End of Pathway Hero Button  ======*/


/*=============================================
=            Pathway Hero Badges            =
=============================================*/
/*----------  Pathway Left Badge  ----------*/
.Pathway-Hero-Badge-Left-1 {
  position: absolute;
  top: 25%;
  left: -7%;
  background-color: transparent;
  display: flex;
  animation-name: rotateBadge45;
  animation-duration: 1.5s;
  will-change: auto;
}
.Pathway-Hero-Badge {
  align-self: flex-start;
  width: 12vw;
  height: 12vw;
}
/*=====  End of Pathway Hero Badges  ======*/


/*=============================================
=            Pathway HERO SVG            =
=============================================*/
.Pathway-Hero-SVG-Container {
  display: flex;
  position: absolute;
  width: 15vw;
  height: 15vw;
  top: 70vh;
  right: 0vw;
  overflow: hidden;
  background-color: transparent;
}
.Pathway-Hero-SVG {
  transform: rotate(90deg);
  height: 100%;
  width: 100%;
  right: 0vw;
  margin: 0;
  position: relative;
  padding: 0;
  overflow: hidden;
  width: auto;
  background-color: transparent;
  animation-duration: 2s;
  animation-name: slideLeft;
  will-change: auto;
}
/*=====  End of Pathway HERO SVG  ======*/



/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes slideLeft {
  from {
    right: -100%;
  }
  to {
    right: 0%;
  }
}
@keyframes slideImage {
  from {
    object-position: 40%;
  }
  to {
    object-position: center;
  }
}
@keyframes rotateBadge45 {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0deg);
  }
  
}
@keyframes zoomOpen {
  0% {
  transform: scale(1, 1);
  }
  50% {
  margin-left: 10vw;
  transform: scale(1.1, 1);
  }
  100% {
  transform: scale(1, 1);
  }
}
@keyframes inAndOut {
  0% {
  transform: scale(1, 1);
  }
  50% {
  transform: scale(0.99, 0.99);
  }
  100% {
  transform: scale(1, 1);
  }
}
/*=====  End of @keyframe Animations  ======*/


/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 768px){
  .Pathway-Hero-Container {
    max-width: 100vw;
    background-color: transparent;
    height: 100vh;
    width: 100%;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .Pathway-Hero-Image {
    object-fit: cover;
    width: 100%;
    height: 40vh;
    position: relative;
    object-position: top;
    will-change: auto;
  }
  .Pathway-Hero-Image[animatehero = '1'] {
    animation: slideImage 2s;
  }
  .Pathway-Hero-Heading-Container {
    right: 0;
    gap: 3vh;
    display: flex;
    position: relative;
    height: 60vh;
    width: 100%;
    padding-left: 10vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #003B55;
  }
  .Pathway-Hero-Heading {
    color: white;
    font-family: Avenir Next Bold; 
    font-weight: 700;
    font-size: 200%;
    position: relative;
    justify-content: center;
    text-align: left;
    word-wrap: break-word;
    width: 90%;
    max-width: 80vw;
    background-color: transparent;
  }
  .Pathway-Hero-Sub-Heading {
    color: #B2D233;
    text-transform: uppercase;
    font-family: Michroma;
    font-weight: 300;
    font-size: 2.5vw;
    position: relative;
    justify-content: center;
    word-spacing: .5vw;
    text-align: left;
    width: 70%;
    background-color: transparent;
  }
  .Pathway-Hero-Summary {
    color: white;
    font-family: Avenir Next;
    font-weight: 300;
    font-size: 70%;
    position: relative;
    justify-content: center;
    text-align: left;
    line-height: 1.5em;
    width: 70%;
    background-color: transparent;
  }
  .Pathway-Hero-Button {
    padding: 10px;
    font-family: Michroma;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid #B2D233;
    color: #B2D233;
  }
  .Pathway-Hero-Button:hover {
    border: 1px solid white;
    color: white;
  }
  .Pathway-Hero-Badge-Left-1 {
    position: absolute;
    top: -5%;
    left: 60%;
    background-color: transparent;
    display: flex;
    will-change: auto;
    animation-name: rotateBadge45;
    animation-duration: 1.5s;
  }
  .Pathway-Hero-Badge {
    align-self: flex-start;
    width: 18vw;
    height: 18vw;
  }
  .Pathway-Hero-SVG-Container {
    display: flex;
    position: absolute;
    width: 15vw;
    height: 15vw;
    top: 70vh;
    right: 0vw;
    overflow: hidden;
    background-color: transparent;
  }
  .Pathway-Hero-SVG {
    transform: rotate(90deg);
    height: 100%;
    width: 100%;
    right: 0vw;
    margin: 0;
    position: relative;
    padding: 0;
    overflow: hidden;
    width: auto;
    background-color: transparent;
    will-change: auto;
    animation-duration: 2s;
    animation-name: slideLeft;
  }
  .Pathway-Hero-Jobs-Container {
    margin-top: 0vh;
    position: relative;
  }
  .Pathway-Hero-Jobs-Heading {
    color: #00A8E5;
    font-size: 70%;
    font-family: Michroma;
    text-transform: uppercase;
  }
  .Pathway-Hero-Jobs {
    padding-top: 1em;
    color: white;
    font-size: 60%;
    width: 70%;
    line-height: 1.5em;
  }
  .Pathway-Hero-Download {
    z-index: 250;
    background-color: transparent;
    width: fit-content;
    height: fit-content;
    will-change: auto;
  }
  .Pathway-Hero-Download[animatehero = '1'] {
    animation: zoomOpen 1.5s;
  }
  .Pathway-Hero-Download:hover {
    animation: inAndOut 1.5s infinite;
  }
  .Pathway-Hero-Download-Button {
    padding: 10px;
    font-size: 1.5vw;
    font-family: Michroma;
    padding-left: 5vw;
    padding-right: 5vw;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }
  .Pathway-Hero-Download-Button:hover {
    border: 1px solid #B2D233;
    color: #B2D233;
    cursor: pointer;
  }
}
/*=====  End of @media Queries  ======*/


