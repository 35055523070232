/*=============================================
=            Footer Styles            =
=============================================*/

/*=============================================
=            Footer Container            =
=============================================*/
.Footer-Container {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 80vh;
  background-color: #003b55;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
}
/*=====  End of Footer Container  ======*/

/*=============================================
=            Footer Header            =
=============================================*/
.Footer-Header {
  padding-left: 4vw;
  font-size: 3vw;
  width: 40%;
  height: fit-content;
  text-transform: uppercase;
  font-family: Avenir Next Bold;
  font-weight: 800;
  color: white;
}
/*=====  End of Footer Header  ======*/

/*=============================================
=            Footer Columns            =
=============================================*/
.Footer-Column-Left {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 80%;
  justify-content: flex-end;
  row-gap: 25vh;
  padding: 5vh;
}
.Footer-Column-Center {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 80%;
  justify-content: flex-end;
  align-items: center;
  row-gap: 20vh;
  padding-bottom: 5vh;
}
.Footer-Column-Right {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  height: 80%;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 4vw;
  row-gap: 5vh;
  padding-bottom: 5vh;
}

/*=====  End of Footer Columns  ======*/

/*=============================================
=            Footer SVGs           =
=============================================*/

/*----------  Green Triangle SVG  ----------*/

.Footer-Triangle-SVG {
  margin-top: -50vh;
  z-index: 150;
  height: 50vh;
  width: 200px;
  left: 0%;
  position: absolute;
  background-color: transparent;
  will-change: auto;
  animation-name: slideRight;
  animation-duration: 2s;
  animation-iteration-count: 1;

}
.Footer-Triangle {
  fill: #b2d233;
  background-color: transparent;
}
/*----------  White Horizontal Line SVG  ----------*/
.SVG-White-Footer {
  position: absolute;
  left: 0;
  background-color: transparent;
  z-index: 150;
  width: 8vw;
  height: 47vh;
}
.White-Footer-Line {
  overflow: hidden;
  fill: transparent;
  stroke-width: 4px;
  stroke: white;
}

/*=====  End of Footer SVGs  ======*/

/*=============================================
=            Footer Icons            =
=============================================*/

.Kingdom-Branding-Icon-Container-Web {
  padding-left: 4vw;
  padding-bottom: 2vh;
  font-family: Avenir Next;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  color: white;
}
.Kingdom-Branding-Icon-Container-Mobile {
  display: none;
}
.Footer-Icon-Center {
  position: relative;
  height: 100%;
  width: 12vw;
}
.Footer-Icon-Left {
  position: relative;
  height: 100%;
  width: 15vw;
}
.Footer-Icon-Right {
  position: relative;
  height: 100%;
  width: 15vw;
}
/*=====  End of Footer Icons  ======*/

/*=============================================
=            Social Media Icons            =
=============================================*/
.Social-Icon {
  width: 2vw;
  height: 1vw;
}
/*=====  End of Social Media Icons  ======*/

/*=============================================
=            Footer Buttons            =
=============================================*/
.Footer-Button {
  width: 20vw;
  padding: 15px;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
}
.Footer-Button:hover {
  border-color: #b2d233;
  color: #003b55;
  background-color: white;
}

/*=====  End of Footer Buttons  ======*/

/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 600px) {
  .Footer-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    gap: 0;
  }
  .Footer-Header {
    padding-top: 0;
    padding-left: 0;
    font-size: 7vw;
    height: 10%;
    top: 500%;
    text-align: center;
  }
  .Footer-Button {
    width: 50vw;
    padding: 2vw;
    font-size: 2vw;
    font-family: Michroma;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    cursor: pointer;
  }
  .Kingdom-Branding-Icon-Container {
    display: flex;
    font-size: 2vw;
    height: fit-content;
    padding: 0;
  }
  .Footer-Column-Center {
    bottom: 5vh;
    height: fit-content;
    width: 100%;
    flex-direction: column;
    background-color: transparent;
    justify-content: space-evenly;
    row-gap: 8vh;
  }
  .Footer-Column-Left {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    background-color: transparent;
  }
  .Footer-Column-Right {
    height: fit-content;
    width: 100%;
    align-items: center;
    background-color: transparent;
    flex-direction: column;
  }
  .Footer-Icon-Left {
    height: 100%;
    width: 20vw;
    background-color: transparent;
  }
  .Footer-Icon-Center {
    height: 100%;
    width: 40vw;
    background-color: transparent;
  }
  .Footer-Icon-Right {
    background-color: transparent;
    height: 100%;
    width: 45vw;
  }
  .Social-Icons {
    left: 0;
    bottom: -5vh;
    height: fit-content;
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: transparent;
    gap: 3vw;
  }
  .Social-Icon {
    padding: 0;
    height: 5vw;
    width: 5vw;
  }
  .Kingdom-Branding-Icon-Container-Web {
    display: none;
  }
  .Kingdom-Branding-Icon-Container-Mobile {
    display: flex;
    padding-left: 4vw;
    padding-bottom: 2vh;
    font-family: Avenir Next;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2vw;
    color: white;
  }
  .SVG-White-Footer {
    display: none;
  }
  .White-Footer-Line {
    display: none;
  }
  .Footer-Triangle {
    display: none;
  }
}
// Medium Break Point
@media screen and (max-width: 1000px) {
  .SVG-White-Footer {
    display: none;
  }
  .White-Footer-Line {
    display: flex;
  }
  .Footer-Triangle {
    display: none;
  }
  .Footer-Button {
    width: 30vw;
  }
}
  
/*=====  End of @media Queries  ======*/
