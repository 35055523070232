
/*=============================================
=            Credentials Function            =
=============================================*/


/*=============================================
=            Credentials Container            =
=============================================*/
.Credentials-Container {
  height: 50vh;
  width: 100%;
  max-width: 100vw;
  background-color: #006885;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2.5vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
}
/*=====  End of Credentials Container  ======*/


/*=============================================
=            Credentials Header            =
=============================================*/
.Credentials-Header {
  font-family: Avenir Next Bold;
  font-size: 3vw;
  color: white;
  width: 20%;
  background-color: transparent;
}

/*=====  End of Credentials Header  ======*/


/*=============================================
=            Credentials Green Line SVG            =
=============================================*/
.SVG-Credentials-Green-Line {
  width: 5%;
  height: 50%;
}
.Credentials-Green-Line {
  stroke: #B2D233;
  stroke-width: 8px;
}
/*=====  End of Credentials Green Line SVG  ======*/



/*=============================================
=            Credentials Column            =
=============================================*/

.Credentials-Column {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  justify-content: space-evenly;
  height: 100%;
  width: 50%;
}

/*=====  End of Credentials Column  ======*/


/*=============================================
=            Credentials Scrolling List            =
=============================================*/

.Credentials-List {
  background-color: transparent;
  width: 100%;
  height: 40vh;
  overflow-y: scroll;
  border: 1px #B2D233 unset;
  scroll-behavior: smooth;
}

/*=====  End of Credentials Scrolling List  ======*/


/*=============================================
=            Credential            =
=============================================*/

/*----------  Credential Container  ----------*/


.Credential-Container {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
/*----------  Credential Title  ----------*/

.Credential-Title {
  width: 100%;
  color: white;
  padding: 1em;
  font-weight: 700;
}
/*----------  Credential Body  ----------*/

.Credential-Body {
  width: 25%;
  color: white;
  padding: 1em;
  font-weight: 700;
  cursor: pointer;
}

/*=====  End of Credential  ======*/






/*=============================================
=            Credentials Green Triangle            =
=============================================*/
.Credentials-Triangle {
  position: absolute;
  right: 0;
  width: 10%;
  height: 50%;
  background-color: transparent;
}
.Triangle-Path {
  fill: #B2D233;
}
/*=====  End of Credentials Green Triangle  ======*/



/*=============================================
=            See More Button            =
=============================================*/

.See-More-Button {
  z-index: 100;
  background-color: transparent;
  margin-top: -5vh;
  font-family: Avenir Next Bold;
  color: white;
  width: 60%;
  align-self: flex-end;
  justify-content: center;
  text-align: center;
  cursor: default;
}
.See-More-Button:hover {
  color: #B2D233;
}

/*=====  End of See More Button  ======*/


/*=============================================
=            Disclaimer            =
=============================================*/
.Credentials-Disclaimer {
  color: white;
  font-family: Avenir Next;
  font-size: 0.8vw;
  width: 90%;
}
/*=====  End of Disclaimer  ======*/


/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 700px) {
  .Credentials-Header {
    font-size: 4vw;
    width: 25vw;
  }
  .Credentials-List {
    font-size: 3vw;
  }
  .Credentials-Disclaimer {
    font-size: 2vw;
    width: fit-content;
  }
  .See-More-Button {
    font-size: 2vw;
    width: 70%;
  }
}

/*=====  End of @media Queries  ======*/








