
/*=============================================
=            Wages Function Styles            =
=============================================*/


/*=============================================
=            Wages Container            =
=============================================*/
.Wages-Container {
  background: #003B55 0% 0% no-repeat padding-box;
  height: 90vh;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  z-index: 50;
}
/*=====  End of Wages Container  ======*/

/*=============================================
=            Wages Column Left            =
=============================================*/
.Wages-Column-Left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  row-gap: 2em;
  width: 50%;
  max-width: 50vw;
  padding-top: 10vh;
  will-change: auto;
}
.Wages-Column-Left[animatewages = '1'] {
  animation: slideWagesLeft 2.5s;
}
/*=====  End of Wages Column Left  ======*/


/*=============================================
=            Wages Column Right            =
=============================================*/
.Wages-Column-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  row-gap: 1em;
  width: 50%;
  max-width: 50vw;
}
.Wages-Column-Right[animatewages = '1'] {
  animation: slideWages 2.5s;
}
/*=====  End of Wages Column Right  ======*/


/*=============================================
=            Wages Header            =
=============================================*/
.Wages-Header {
  padding: 0;
  width: 60%;
  height: 15%;
  font-family: Avenir Next Bold;
  color: white;
  font-size: 3.5vw;
  text-align: left;
  background-color: transparent;
}
/*=====  End of Wages Header  ======*/


/*=============================================
=            Wages Summary            =
=============================================*/
.Wages-Summary {
  width: 60%;
  line-height: 4vh;
  color: white;
}
/*=====  End of Wages Summary  ======*/


/*=============================================
=            Wages Details            =
=============================================*/
.Wages-Details {
  width: 60%;
  color: white;
  line-height: 2em;
  font-family: Avenir Next;
}
/*=====  End of Wages Details  ======*/


/*=============================================
=            Wages Employment Circle            =
=============================================*/
/*----------  Circle Container  ----------*/
.Employment-Circle {
  height: 15vw;
  width: 15vw;
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10vh;
  left: 0vw;
  border: 1.5vw #00A8E5 solid;
  border-radius: 50%;
  background-color: #006885;
}

/*----------  Circle Header  ----------*/
.Employment-Circle-Header {
  color: white;
  font-family: Michroma;
  text-transform: uppercase;
  font-size: 1vw;
}

/*----------  Circle Employment Data  ----------*/
.Employment-Circle-Data {
  color: white;
  font-family: Avenir Next Bold;
  font-size: 3.3vw;
}
/*=====  End of Wages Employment Circle  ======*/


/*=============================================
=            Wage Bars            =
=============================================*/
.Wage-Bar {
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(to right, #006885, #006582);
  box-shadow: 0px 0px 40px #AF8E8E0F;
  padding: 2em;
  width: auto;
}
.Wage-Bar-Total {
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 0px 40px #AF8E8E0F;
  padding: 2em;
}
.Wage-Bar-Title {
  font-size: 1.5vw;
  font-family: Avenir Next Bold;
  color: #B2D233;
}
.Wage-Bar-Title-Blue {
  font-size: 1.5vw;
  font-family: Avenir Next Bold;
  color:#003B55;
}
.Wage-Data {
  padding-right: 3vw;
  font-weight: 700;
  font-size: 1.6vw;
  color: white;
}
.Wage-Data-Blue {
  padding-right: 3vw;
  font-weight: 700;
  font-size: 1.6vw;
  color: #006885;
}
/*=====  End of Wage Bars  ======*/



/*=============================================
=            Updated Data Date           =
=============================================*/
.Updated-Date {
  color: white;
  font-size: .5vw;
  font-family: Michroma;
  text-transform: uppercase;
  text-align: right;
  padding-right: 5vw;
  letter-spacing: 0.7px;
}
/*=====  End of Updated Data Date  ======*/


/*=============================================
=            Statistics Link            =
=============================================*/

.Statistics-Link {
  padding: 0.5em;
}

.Statistics-Link:visited {
  color: white;
}
.Statistics-Link:hover {
  color: #B2D233;
}

/*=====  End of Statistics Link  ======*/





/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes slideWagesLeft {
  from {
    left: 0;
    padding-right: 15vw;
    background-color:#00A8E5;
  }
  to {
    left: 0; 
  }
}
@keyframes slideWages {
  from {
    right: 0;
    padding-left: 35vw;
    background-color:#00A8E5;
  }
  to {
    right: 0; 
  }
}
/*=====  End of @keyframe Animations  ======*/


/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 912px) {
  .Wages-Container {
    flex-direction: column;
    height: fit-content;
  }
  .Wages-Header {
    font-size: 8vw;
    align-self: flex-start;
    padding-left: 10vw;
  }
  .Wages-Column-Left {
    width: 100%;
    padding-top: 5vh;
    max-width: 100vw;
    padding-bottom: 0;
    height: 45vh;
    background-color: transparent;
  }
  .Wages-Summary {
    width: 80%;
  }
  .Wages-Details {
    font-size: 2.8vw;
    width: 80%;
  }
  .Wages-Column-Right {
    width: 80%;
    height: 50%;
    align-self: flex-end;
    max-width: 100vw;
    background-color: transparent;
    padding-bottom: 3vh;
  }
  .Wages-Column-Left[animatewages = '1'] {
    animation: none;
  }
  .Wages-Column-Right[animatewages = '1'] {
    animation: none;
  }
  .Wage-Bar-Title {
    font-size: 90%;
    width: fit-content;
  }
  .Wage-Bar-Title-Blue {
    font-size: 90%;
    width: fit-content;
  }
  .Wage-Data-Blue {
    font-size: 6vw;
  }
  .Wage-Data {
    font-size: 5vw;
  }
  .Employment-Circle-Header {
    font-size: 2vw;
  }
  .Employment-Circle {
    top: 0;
    left: 15vw;
    align-self: flex-start;
  }
  .Updated-Date {
    font-size: 2vw;
  }
}
@media print and (max-width: 1500px) {
  .Wages-Column-Left {
    background-color: transparent;
  }
}
/*=====  End of @media Queries  ======*/





