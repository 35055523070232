
/*=============================================
=   JumbotronPhoto Function Styles           =
=============================================*/

/*=============================================
=            Main Function Containers          =
=============================================*/
.Jumbotron-Photo-1,
.Jumbotron-Photo-2,
.Jumbotron-Photo-3,
.Jumbotron-Photo-4,
.Jumbotron-Photo-5,
.Jumbotron-Photo-6 {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  z-index: 100;
  display: flex;
  background-size: cover;
  background-position: bottom;
  justify-content: flex-end;
}
// Background Images
.Jumbotron-Photo-1 {
  background-image: url('../../assets/images/webp/pathway-home.webp');
}
.Jumbotron-Photo-2 {
  background-image: url('../../assets/images/webp/entry-bottom.webp');
}
.Jumbotron-Photo-3 {
  background-image: url('../../assets/images/webp/boxes-bottom.webp');
}
.Jumbotron-Photo-4 {
  background-image: url('../../assets/images/webp/careerpagejumbophoto.webp');
}
.Jumbotron-Photo-5 {
  background-image: url('../../assets/images/webp/advancementjumboimage.webp');
}
.Jumbotron-Photo-6 {
  background-image: url('../../assets/images/webp/aboutjumbophoto.webp');
}
/*=====  End of main function containers  ======*/

/*=============================================
=            Header            =
=============================================*/
.Jumbo-Header {
  height: fit-content;
  text-transform: uppercase;
  color: white;
  font-size: 3.5vw;
  font-family: Avenir Next Bold;
}
/*=====  End of Header  ======*/

/*=============================================
=          Paragraph Section Block            =
=============================================*/
.Jumbo-Paragraph-Container {
  overflow-x: hidden;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 5vh;
  width: 40%;
  padding: 2em;
  padding-bottom: 5em;
  will-change: auto
}
.Jumbo-Paragraph-Container[slideinjumbo='1'] {
  animation-name: slideInJumbo;
  animation-duration: 2s;
}
.Jumbo-Paragraph {
  color: white;
  line-height: 2em;
  font-family: Avenir Next;
  width: 80%;
}
/*=====  End of Paragraph Section  ======*/


/*=============================================
=            Button            =
=============================================*/
.Jumbo-Photo-Button {
  padding: 15px;
  padding-right: 25px;
  padding-left: 25px;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  width: fit-content;
}
.Jumbo-Photo-Button:hover {
  border: 1px solid #b2d233;
  color: #b2d233;
  cursor: pointer;
}
/*=====  End of Button  ======*/


/*=============================================
=            @Media Queries            =
=============================================*/
@media screen and (max-width: 720px) {
  // Jumbo Containers
  .Jumbotron-Photo-1,
  .Jumbotron-Photo-2,
  .Jumbotron-Photo-4,
  .Jumbotron-Photo-5,
  .Jumbotron-Photo-6 {
    height: fit-content;
    width: 100%;
    max-width: 100vw;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: bottom;
    background-size: cover;
    background-position: bottom;
  }
  .Jumbotron-Photo-3 {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: bottom;
  }
  .Jumbo-Paragraph-Container {
    width: 80%;
    height: fit-content;
    align-items: center;
  }
  .Jumbo-Paragraph {
    font-size: 3.5vw;
    color: white;
    width: 100%;
  }
  .Jumbo-Photo-Button {
    font-size: 2vw;
    font-weight: 600;
    align-self: center;
    color:white;
    border: white solid 1px;
  }
  .Jumbo-Paragraph-Container[slideinjumbo='1'] {
    animation: none;
  }
}
/*=====  End of @Media Queries  ======*/


/*=============================================
=            Keyframes            =
=============================================*/
@keyframes slideInJumbo {
  from {
    width: 5%;
    color: transparent;
  }
  to {
    width: 40%;
  }
}
/*=====  End of Keyframes  ======*/

