

/*=============================================
=            Paragraph Styles            =
=============================================*/



/*=============================================
=            Paragraph Container            =
=============================================*/
.Paragraph-Container {
  display: flex;
  height: 80vh;
  width: 100%;
  max-width: 100vw;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #006885;
  align-items: center;
  overflow: hidden;
}
/*=====  End of Paragraph Container  ======*/


/*=============================================
=            Paragraph Columns            =
=============================================*/
.Paragraph-Left {
  display: flex;
  top: 0;
  height: 100%;
  width: 50%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  row-gap: 3vh;
  padding-left: 10vw;
  overflow: hidden;
  background-color: transparent;
  will-change: auto;
}
.Paragraph-Left[slideinparagraph = '1'] {
  animation-name: slideInParagraph;
  animation-duration: 2s;
}
.Paragraph-Right {
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 45%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

/*=====  End of Paragraph Columns  ======*/


/*=============================================
=            Paragraph Header            =
=============================================*/
.Paragraph-Header {
  font-family: Avenir Next Bold;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 45px;
  color: white;
}
/*=====  End of Paragraph Header  ======*/


/*=============================================
=            Paragraph Body Text            =
=============================================*/
.Paragraph-Paragraph {
  color: white;
  text-align: left;
  width: 90%;
  line-height: 2em;
  background-color: transparent;
  font-family: Avenir Next;
  font-size: medium;
}
/*=====  End of Paragraph Body Text  ======*/


/*=============================================
=            Paragraph Image            =
=============================================*/
.Paragraph-Image {
  right: 0;
  position: absolute;
  width: 40vw;
  height: 42vw;
  z-index: 10;
  object-fit: cover;
}
/*=====  End of Paragraph Image  ======*/


/*=============================================
=            Paragraph Buttons            =
=============================================*/

.Learn-More-Button-White {
  padding: 10px;
  font-size: medium;
  padding-left: 5vw;
  padding-right: 5vw;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
}
.Learn-More-Button-White:hover {
  border: 1px solid #B2D233;
  color: #B2D233;
  cursor: pointer;
}

/*=====  End of Paragraph Buttons  ======*/


/*=============================================
=            @media Queries            =
=============================================*/

// Main Screen Size Break
@media screen and (max-width: 700px) { 
  .Paragraph-Container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: 90vh;
  }
  .Paragraph-Image {
    width: 80%;
    height: 25vh;
    object-fit: cover;
  }
  .Paragraph-Header {
    font-size: 6.5vw;
    width: 100%;
  }
  .Paragraph-Left {
    width: fit-content;
    background-color: transparent;
    height: fit-content;
    padding-bottom: 10vh;
    padding-left: 10vw;
  }
  .Paragraph-Left[slideinparagraph = '1'] {
    animation: none;
  }
  .Paragraph-Right {
    height: 30%;
  }
  .Paragraph-Header {
    align-self: center;
  }
  .Paragraph-Paragraph {
    width: 90%;
    line-height: 3vh;
    font-size: 3.8vw;
  }
  .Learn-More-Button-White {
    font-size: 2vw;
    padding-right: 8vw;
    padding-left: 8vw;
  }
}

/*=====  End of @media Queries  ======*/


/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes slideInParagraph {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
/*=====  End of @keyframe Animations  ======*/





