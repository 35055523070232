/*=============================================
=            ProgramLearn Styles            =
=============================================*/

/*----------  Main Styling  ----------*/

/*=============================================
=            Main Containers            =
=============================================*/

.Program-Learn-Container {
  width: 100%;
  max-width: 100vw;
  height: 70vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  opacity: 0;
}
.Program-Learn-Container[drape='1'] {
  opacity: 1;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Left Column            =
=============================================*/

/*----------  Left Column Main Container ----------*/

.Learn-Content-Left-Container {
  display: flex;
  flex-direction: column;
  width: 63%;
  height: 60vh;
  min-height: auto;
  align-items: center;
  justify-content: center;
  padding: 1%;
  z-index: 2;
  will-change: auto;
}
.Learn-Content-Left-Container[drape='1'] {
  animation: drapeeffectleft 0.9s;
}

/*----------  Left Column Text  ----------*/

.Learn-Text-Container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  will-change: auto;
  height: auto;
}
.Learn-Text-Container[drape='1'] {
  animation: revealtext 2s;
}
.Learn-Heading-Text {
  color: white;
  font-family: Avenir Next Bold;
  font-size: 52px;
  font-weight: 800;
}
.Learn-Paragraph-Text {
  color: white;
  font-size:medium;
  line-height: 1.7em;
  font-family: Avenir Next;
  width: 100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

/*----------  Left Column List  ----------*/

.Learn-List {
  color: white;
  font-family: Avenir Next, Medium;
  align-self: flex-end;
  padding-right: 0vw;
  font-size:medium;
  line-height: 2.3vw;
  text-indent: -1em;
}

/*=====  End of Left Column  ======*/

/*=============================================
=            Right Column            =
=============================================*/

.Learn-Content-Right-Container {
  background-size: cover;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 70vh;
  will-change: auto;
}
.Learn-Content-Right-Container[drape='1'] {
  animation: drapeeffectright 1.2s;
}

/*=====  End of Right Column  ======*/

/*=============================================
=            Button For Career Page            =
=============================================*/

.Register-Button {
  padding: 10px;
  width: 50%;
  font-family: Michroma;
  letter-spacing: 2px;
  font-weight: 700;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  line-height: 2rem;
}
.Register-Button:hover {
  border: 1px solid white;
  background-color: #00a8e5;
  color: white;
  cursor: pointer;
}

/*=====  End of Button For Career Page  ======*/

/*=============================================
=            Keyframe Animations            =
=============================================*/

@keyframes drapeeffectleft {
  from {
    width: 0%;
  }
  to {
    width: 63%;
  }
}
@keyframes mobiledrapeeffectleft {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes drapeeffectright {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
@keyframes mobiledrapeeffectright {
  from {
    width: 0%;
  }
  to {
    width: 80%;
  }
}
@keyframes revealtext {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/
@media (max-width: 1005px){
  .Learn-Heading-Text {
    font-size: 43px;
    font-weight: 800;
  }
  .Learn-Paragraph-Text {
    color: white;
    font-size: 20px;
    line-height: 1.5em;
    padding-top: 3vh;
    padding-bottom: 3vh;
  }
  .Learn-List {
    font-size: 19px;
    line-height: 2.5vw;
  }
}
@media (max-width: 800px) {
  .Program-Learn-Container {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 190vw;
  }
  .Learn-Content-Left-Container {
    flex-direction: column;
    width: 100vw;
    height: 109vw;
    padding: 0;
    will-change: auto;
  }
  .Learn-Content-Left-Container[drape='1'] {
    animation: mobiledrapeeffectleft 1s;
  }
  .Learn-Content-Right-Container {
    flex-direction: row;
    width: 80vw;
    height: 85vw;
    will-change: auto;
  }
  .Learn-Content-Right-Container[drape='1'] {
    animation: mobiledrapeeffectright 3s;
  }
  .Learn-Heading-Text {
    font-size: 7vw;
    text-align: left;
  }
  .Learn-Paragraph-Text {
    font-size: 3.1vw;
    text-align: left;
  }
  .Learn-List {
    font-size: 2.7vw;
    width: 70%;
    text-align: left;
    line-height: 5.2vw;
  }
  .Learn-Text-Container {
    width: 75%;
    height: 70%;
    text-align: center;
  }
}
@media (max-width: 700px) {
    .Learn-Paragraph-Text {
    font-size: 3.8vw;
  }
}
@media (max-width: 420px) {
  .Program-Learn-Container {
    width: 100%;
    max-width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    opacity: 0;
  }
  .Learn-Heading-Text {
    color: white;
    font-family: Avenir Next Bold;
    font-size: 6.3vw;
    font-weight: 800;
    padding-bottom: 2vw;
  }
  .Learn-Paragraph-Text {
    color: white;
    font-size: 3.7vw;
    line-height: 1.9em;
    font-family: Avenir Next;
    width: 100%;
    padding-top: 2.3vh;
    padding-bottom: 2.3vh;
  }
  .Learn-List {
    color: white;
    font-family: Avenir Next, Medium;
    align-self: flex-end;
    padding-right: -3vw;
    font-size: 3.3vw;
    line-height: 5vw;
    text-indent: -1em;
  }
  .Learn-Content-Left-Container {
    height: 132vw;
  }
  .Register-Button {
    padding: 5px;
    width: 80%;
    font-family: Michroma;
    letter-spacing: 2px;
    font-weight: 700;
    padding-left: 25px;
    padding-right: 25px;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid white;
    color: white;
    line-height: 1.3rem;
  }
}
@media only screen and (max-height: 880px) and (min-height: 880px) {
    .Program-Learn-Container {
    width: 100%;
    max-width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    opacity: 0;
    padding-bottom: 5vh;
  }
  .Learn-Content-Right-Container {
  height: 50%;
  }
  .Learn-Content-Left-Container {
  height: 60%;
}
}
/*=====  End of @Media Queries  ======*/

