
/*=============================================
=            Schedule Styles            =
=============================================*/



/*=============================================
=            Schedule Container            =
=============================================*/
.Schedule-Container {
  display: flex;
  height: 150vh;
  width: 100%;
  max-width: 100vw;
}
/*=====  End of Schedule Container  ======*/


/*=============================================
=            Schedule Columns            =
=============================================*/
.Schedule-Column-Left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 100%;
  width: 50%;
  background-color: transparent;
}
.Schedule-Column-Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  background-color: transparent;
}
/*=====  End of Schedule Columns  ======*/


/*=============================================
=            Schedule Cards           =
=============================================*/
.Schedule-Card {
  width: 50%;
  padding: 3em;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 40px #00000012;
}
.Schedule-Green-Card {
  position: absolute;
  z-index: 50;
  height: 40vw;
  width: 20vw;
  left: 15vw;
  transform: rotate(-5deg);
  background-color: #B2D233;
}
/*=====  End of Schedule Cards  ======*/


/*=============================================
=            Schedule Header            =
=============================================*/
.Schedule-Sub-Header {
  font-family: Michroma;
  text-transform: uppercase;
  color: #006885;
}
.Schedule-Header {
  color: #B2D233;
  font-family: Avenir Next Bold;
  font-size: 3vw;
}
/*=====  End of Schedule Header  ======*/



/*=============================================
=            Schedule List            =
=============================================*/
.Schedule-List {
  list-style: none;
}
.Schedule-List-Item {
  padding-top: 2em;
  color: #707070;
  font-weight: 600;
}
/*=====  End of Schedule List  ======*/


/*=============================================
=            Schedule Disclaimer            =
=============================================*/
.Schedule-Disclaimer {
  color: #707070;
  padding-top: 2em;
  font-size: 0.8vw;
  width: 80%;
}
/*=====  End of Schedule Disclaimer  ======*/


/*=============================================
=            Schedule Image            =
=============================================*/
.Schedule-Image {
  position: relative;
  width: 65%;
  height: 55%;
  object-fit: cover;
}
/*=====  End of Schedule Image  ======*/






/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 900px) {
  .Schedule-Container {
    background-color: transparent;
    margin-top: 0;
    padding-top: 0vh;
    flex-direction: column;
    height: fit-content;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
  .Schedule-Column-Left {
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
  }
  .Schedule-Card {
    width: 50%;
  }
  .Schedule-List {
    background-color: transparent;
  }
  .Schedule-List-Item {
    font-size: 3vw;
  }
  .Schedule-Column-Right {
    width: 100%;
    height: 100%;
    align-items: center;
    padding-top: 0;
  }
}
/*=====  End of @media Queries  ======*/


