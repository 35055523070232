/*=============================================
=            ProgramTimeline Styles            =
=============================================*/
#linkUp {
  z-index: 4;
}
/*=============================================
=            Main Containers           =
=============================================*/

.Program-Timeline {
  height: auto;
  width: 100%;
  max-width: 100vw;
  background-color: rgba(0, 168, 229, 0.04);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Text            =
=============================================*/

/*----------  Headings  ----------*/

.Timeline-Headings-Container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  position: relative;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  padding-bottom: 10vh;
}
.Timeline-Heading-One {
  font-size: 1.8vw;
  color: #90d12a;
  position: absolute;
  left: 28.6vw;
  font-weight: lighter;
  word-spacing: 0.6vw;
  will-change: auto;
}
.Timeline-Heading-One[animatetimelineheading='1'] {
  animation-name: slideinheading1;
  animation-duration: 1s;
}
.Timeline-Heading-Two {
  font-size: 64px;
  color: #003b55;
  font-family: Avenir Next Bold;
  position: relative;
  margin-top: 4vw;
  margin-bottom: 2vw;
  text-align: center;
  will-change: auto;
}
.Timeline-Heading-Two[animatetimelineheading='1'] {
  animation-name: slideinheading2;
  animation-duration: 2.5s;
}

/*----------  Paragraphs  ----------*/

.Timeline-Details {
  text-align: center;
  font-size:medium;
  line-height: 1.9em;
  font-family: Avenir Next;
  width: 45%;
  word-wrap: break-word;
  color: #707070;
  will-change: auto;
}
.Timeline-Details[animatetimelineheading='1'] {
  animation-name: slideinheading2;
  animation-duration: 3s;
}

/*=====  End of Text  ======*/

/*=============================================
=            Ghost Element Block            =
    *Reveals the SVG lines in the chart.
=============================================*/

.Reveal-Cards-Block {
  position: absolute;
  background-color: #f5fcfe;
  width: 0vw;
  height: 30vw;
  z-index: 3;
  will-change: auto;
}
.Reveal-Cards-Block[animatetimeline='1'] {
  animation: reveallines 0.7s;
  animation-timing-function: ease-in-out;
}

/*=====  End of Ghost Element Block  ======*/

/*=============================================
=            Chart Main Containers            =
=============================================*/

/*----------  Whole Chart Container (ProgramTimeline.jsx) ----------*/

.Timeline-Chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-evenly;
  height: auto;
  width: 100vw;
  position: relative;
}

/*----------  Singular Chart Container  ----------*/

.Timeline-Chart-Container {
  height: 45vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

/*----------  Both Charts Container  ----------*/

.Timeline-Charts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98vh;
  width: 100vw;
  position: relative;
  padding-bottom: 6vw;
}

/*=====  End of Chart Main Containers  ======*/

/*=============================================
=            Timeline Content            =
=============================================*/

.Program-Timeline-Content-Container {
  width: 100vw;
  height: 87%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*=====  End of Content  ======*/

/*=============================================
=            Chart Card Main Containers            =
=============================================*/

.Timeline-Card-Container {
  min-width: 18vw;
  min-height: auto;
  max-width: 17vw;
  max-height: 30vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  z-index: 4;
  margin-bottom: 0.4vw;
  will-change: auto;
}
.Timeline-Card-Container:hover {
  box-shadow: 0px 0px 5px #90d12a;
  animation: breathe 1s infinite;
}
.Timeline-Card-Container-First-Green {
  min-width: 18vw;
  min-height: auto;
  max-width: 17vw;
  max-height: 30vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  border: 2.5px solid #6cb706;
  z-index: 3;
  will-change: auto;
}
.Timeline-Card-Container-First-Green:hover {
  animation: liftfirstcardgreen 1s;
  animation-duration: infinite;
  animation-timing-function: linear;
  box-shadow: 0px 8px 18px #6cb706;
}
.Timeline-Card-Container-First-Blue {
  min-width: 18vw;
  min-height: auto;
  max-width: 17vw;
  max-height: 30vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  border: 2.3px solid #00a8e5;
  z-index: 4;
  will-change: auto;
}
.Timeline-Card-Container-First-Blue:hover {
  animation: liftfirstcardblue 1s;
  animation-duration: infinite;
  animation-timing-function: linear;
  box-shadow: 0px 8px 18px #00a8e5;
}
/*=====  End of Chart Card Main Containers  ======*/

/*=============================================
=            Chart Card Content            =
=============================================*/

/*----------  Bar Separator  ----------*/

hr {
  border: 0.5px solid #e6e6e6;
}

/*----------  Headings  ----------*/

.Timeline-Card-Heading1 {
  text-align: left;
  font-size: 1vw;
  padding: 18px;
  letter-spacing: 0px;
  color: #006885;
  text-transform: uppercase;
}
.Timeline-Card-Heading2 {
  text-align: left;
  font-family: Avenir Next Bold;
  font-size: 1.4vw;
  padding: 1vw;
}

/*----------  Card Paragraph (Not in Use)  ----------*/

.Timeline-Card-Description {
  text-align: left;
  font-family: Avenir, Book;
  color: #707070;
  font-size: 13px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
  width: 15vw;
}

/*=====  End of Chart Card Content  ======*/

/*=============================================
=            Chart Card Specific Containers            =
=============================================*/

/*----------  Career Type Chart Card Container  ----------*/

.Career-Type-Card-Container {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 21%;
  z-index: 4;
  padding-left: 3vw;
  justify-content: space-between;
}

/*----------  Career Type Chart Card Single Container  ----------*/

.Career-Type-Card-Single-Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  z-index: 4;
}

/*----------  Advanced Type Chart Card Containers  ----------*/

.Career-Adv-Card-Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 23%;
  z-index: 4;
  justify-content: space-between;
}
.Career-Adv-Card-Row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  background-color: blue;
}
.Timeline-Card-Quality-Contianer {
  z-index: 4;
}

/*----------  Column for first Chart Cards  ----------*/

.Timeline-Card-Assembler {
  display: flex;
  flex-direction: column;
  width: 10%;
  height: 85%;
  justify-content: flex-end;
}

/*=====  End of Chart Card Specific Containers  ======*/

/*=============================================
=            SVG Lines            =
=============================================*/

.Line-Path-Green {
  fill: none;
  stroke: #6cb706;
  background-color: black;
}
.Line-Path-Blue {
  fill: none;
  stroke: #00a8e5;
}
.Line-1-SVG {
  width: 120%;
  height: 34vw;
}
.Line-2-SVG {
  height: 38vw;
  width: 120%;
}
.Line-3-SVG {
  height: 50%;
  width: 100%;
}
.Line-4-SVG {
  height: 58%;
  width: 90%;
}
.Line-5-SVG {
  height: 56%;
  width: 100%;
}
.Line-6-SVG {
  height: 100%;
  width: 100%;
}
.Line-7-SVG {
  height: 100%;
  width: 100%;
}

/*----------  Specific Line Containers  ----------*/

.Line-1-2-Container {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: flex-start;
  align-items: flex-start;
  width: 33vw;
  height: 55vh;
  left: 23vw;
  top: 9vh;
  overflow: hidden;
  z-index: 2;
}
.Line-3-4-Container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 36vw;
  height: 45vh;
  left: 52vw;
  top: 1vh;
  z-index: 2;
}
.Line-5-Container {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 26vw;
  height: 60vh;
  left: 23vw;
  top: 40vh;
  overflow: hidden;
  z-index: 0;
}
.Line-6-7-Container {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 370px;
  left: 48vw;
  top: 51vh;
  overflow: hidden;
  z-index: 0;
}

/*=====  End of SVG Lines  ======*/

/*=============================================
=            Mobile Timeline Main Containers            =
=============================================*/
.Mobile-Timeline-Charts-Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
  height: auto;
  overflow: hidden;
}
.Timeline-Mobile-Chart-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  height: 160vw;
  max-height: auto;
  padding-top: 6vw;
  padding-bottom: 6vw;
}

/*=====  End of Mobile Timeline Main Containers  ======*/

/*=============================================
=            Mobile Ghost Reveal Element            =
        *Reveals the SVG lines in the chart.
=============================================*/

.Reveal-Cards-Block-Mobile {
  position: absolute;
  background-color: #f5fcfe;
  width: 100vw;
  height: 100%;
  z-index: 2;
  top: 100%;
  will-change: auto;
}
.Reveal-Cards-Block-Mobile[reveallinesmobile='1'] {
  animation-name: mobilereveallines;
  animation-duration: 6.5s;
  animation-timing-function: linear;
}

/*=====  End of Ghost Reveal Element  ======*/

/*=============================================
=           Specific Mobile Chart Card Containers            =
=============================================*/

.Timeline-Mobile-Advancement-Bottom-Chart-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  height: 160vw;
  max-height: auto;
  padding-top: 6vw;
  padding-bottom: 10vw;
}

/*----------  Career Type Cards Specific Row Container  ----------*/

.Mobile-Career-Card-Row-Bottom {
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  top: 28vw;
}
.Mobile-Career-Card-Row-Bottom-2 {
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  top: 25.5vw;
}

/*----------  Advancement Type Cards Specific Row Containers  ----------*/

.Mobile-Advancement-Card-Row-Bottom {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  margin-top: 2.5vw;
}

/*----------  Different Variations For Each Page  ----------*/

.Mobile-Career-Card-First-Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 65.5%;
  position: relative;
  margin-top: -30vw;
  height: auto;
}
.Mobile-Career-Card-First-Container-Flipped {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 65.5%;
  position: relative;
  margin-top: -30vw;
  height: auto;
}
.Mobile-Career-Card-Second-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65.5%;
  position: relative;
  height: auto;
}

/*=====  End of Specific Mobile Chart Card Containers  ======*/

/*=============================================
=            First Mobile Chart Cards            =
=============================================*/
#mobileLinkUp {
  z-index: 5;
}
/*----------  Mobile Green First Card  ----------*/

.Timeline-Mobile-Card-First-Green {
  width: 65%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  border: 2.5px solid #6cb706;
  z-index: 4;
}

/*----------  Mobile Blue First Card  ----------*/

.Timeline-Mobile-Card-First-Blue {
  width: 65%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  border: 2.5px solid #00a8e5;
  z-index: 4;
}

/*----------  Mobile First Card Text  ----------*/

.Timeline-Mobile-Card-First-Heading-1 {
  text-align: left;
  font-size: 2.9vw;
  padding: 3vw;
  letter-spacing: 0px;
  color: #006885;
  text-transform: uppercase;
  font-weight: lighter;
}
.Timeline-Mobile-Card-First-Heading-2 {
  text-align: left;
  font-family: Avenir Next Bold;
  font-size: 5vw;
  padding: 3vw;
}

/*=====  End of First Mobile Chart Cards  ======*/

/*=============================================
=            Mobile Card            =
=============================================*/

/*----------  Mobile Card Container  ----------*/

.Timeline-Mobile-Card {
  width: 68%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  z-index: 3;
}
.Timeline-Mobile-Card-EV {
  display: flex;
  width: 70%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  z-index: 3;
}

/*----------  Mobile Card Text  ----------*/

.Timeline-Mobile-Card-Heading-1 {
  text-align: left;
  font-size: 2.3vw;
  padding: 3vw;
  letter-spacing: 0px;
  color: #006885;
  text-transform: uppercase;
  font-weight: lighter;
}
.Timeline-Mobile-Card-Heading-2 {
  text-align: left;
  font-family: Avenir Next Bold;
  font-size: 3.7vw;
  padding-top: 3vw;
  padding-left: 3vw;
  padding-bottom: 6.5vw;
  width: 85%;
  height: auto;
}
.Timeline-Mobile-Card-Heading-2-EV {
  text-align: left;
  font-family: Avenir Next Bold;
  font-size: 3.1vw;
  padding-top: 3vw;
  padding-left: 3vw;
  padding-bottom: 7.2vw;
  width: 85%;
  height: auto;
}

/*=====  End of Mobile Card  ======*/

/*=============================================
=            Mobile Advance Type Card            =
=============================================*/

.Mobile-Advance-Card {
  width: 54%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000000a;
  z-index: 3;
}

/*=====  End of Mobile Advance Type Card  ======*/

/*=============================================
=            Mobile SVG Lines            =
=============================================*/

.Mobile-Line-1-SVG {
  width: 80px;
  height: 60vw;
  position: absolute;
  top: 17vw;
  right: 64vw;
  z-index: 1;
  overflow: hidden;
}
.Mobile-Line-2-SVG {
  width: 80px;
  height: 70vw;
  position: absolute;
  top: 17vw;
  right: 23vw;
  z-index: 1;
  overflow: hidden;
}
.Mobile-Line-3-SVG {
  width: 80px;
  height: 50vw;
  position: absolute;
  top: 50vw;
  right: 25vw;
  z-index: 1;
  overflow: hidden;
}
.Mobile-Line-4-SVG {
  width: 80px;
  height: 60vw;
  position: absolute;
  bottom: 90vw;
  right: 45vw;
  z-index: 1;
  overflow: hidden;
}
.Mobile-Line-5-SVG {
  width: 80px;
  height: 60vw;
  position: absolute;
  top: 20vw;
  right: 43%;
  z-index: 1;
  overflow: hidden;
}
.Mobile-Line-6-SVG {
  width: 300px;
  height: 80vw;
  position: absolute;
  top: -40vw;
  left: 13vw;
  z-index: 1;
  overflow: hidden;
}
/*=====  End of Mobile SVG Lines  ======*/

/*=============================================
=            Ghost Element            =

*This ghost element is to hide an svg line below an 
 advanced type card for the Advancement Page Chart.
=============================================*/

.Ghost-Element {
  display: flex;
  width: 43vw;
  height: 50vw;
  z-index: 2;
  position: absolute;
  background-color: #f5fcfe;
  top: 20vw;
}

/*=====  End of Ghost Element  ======*/

/*=============================================
=            Keyframe Animations            =
=============================================*/
@keyframes liftfirstcardgreen {
  from {
  box-shadow: 0px 0px 0px #6cb706;
  } to {
  box-shadow: 0px 8px 18px #6cb706;
  }
}
@keyframes liftfirstcardblue {
  from {
  box-shadow: 0px 0px 0px #00a8e5;
  } to {
  box-shadow: 0px 8px 18px #00a8e5;
  }
}
@keyframes slideinheading1 {
  from {
    left: -100vw;
  }
  to {
    left: 28.6vw;
  }
}
@keyframes mobileslideinheading1 {
  from {
    left: -100vw;
  }
  to {
    left: 0vw;
  }
}
@keyframes slideinheading2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes breathe {
  0% {
    padding: 0;
  }
  50% {
    padding: 0.1vw;
  }
  100% {
    padding: 0;
  }
}
@keyframes reveallines {
  from {
    width: 100vw;
  }
  to {
    width: 0vw;
  }
}
@keyframes mobilereveallines {
  from {
    top: 0%;
  }
  to {
    top: 100%;
  }
}
@keyframes cardappear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/

@media (max-width: 800px) {
  .Timeline-Headings-Container {
    width: 100vw;
    height: 34vw;
    padding-bottom: 0vh;
  }
  .Timeline-Heading-One {
    font-size: 2.8vw;
    position: relative;
    left: 0vw;
    will-change: auto;
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .Timeline-Heading-One[animatetimelineheading='1'] {
    animation-name: mobileslideinheading1;
    animation-duration: 1s;
  }
  .Timeline-Heading-Two {
    font-size: 5.3vw;
    position: relative;
    right: 0vw;
    margin: 0vw;
    padding-left: 8vw;
    padding-right: 8vw;
  }
  .Timeline-Details {
    text-align: center;
    font-size: 2.6vw;
    line-height: 1.9em;
    font-family: Avenir Next;
    width: 65%;
    word-wrap: break-word;
    color: #707070;
  }
  .Timeline-Card-Container {
    min-width: 18vw;
    min-height: auto;
    max-width: 17vw;
    max-height: 30vh;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000000a;
    z-index: 4;
    margin-bottom: 0.4vw;
  }
}
@media (max-width: 700px) {
    .Timeline-Details {
    text-align: center;
    font-size: 3.8vw;
    line-height: 1.5em;
    font-family: Avenir Next;
    width: 65%;
    word-wrap: break-word;
    color: #707070;
  }
    .Timeline-Headings-Container {
    height: 50vw;
  }
}
@media (max-width: 500px) {
  .Timeline-Details {
    text-align: center;
    font-size: 3vw;
    line-height: 1.9em;
    font-family: Avenir Next;
    width: 80%;
    word-wrap: break-word;
    color: #707070;
  }
    .Timeline-Headings-Container {
    width: 100vw;
    height: 34vw;
    padding-bottom: 0vh;
  }
}
@media (max-width: 1040px) {
  .Timeline-Card-Container {
    min-width: 22vw;
    min-height: 13vw;
    max-width: 17vw;
    max-height: 60vh;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000000a;
    z-index: 4;
    margin-bottom: 0vw;
  }
  .Career-Type-Card-Container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 23%;
    z-index: 4;
    justify-content: space-between;
  }
  .Timeline-Card-Assembler {
    display: flex;
    flex-direction: column;
    width: 17%;
    height: 84%;
    justify-content: flex-end;
  }
  .Timeline-Card-Container-First-Green {
    min-width: 21vw;
    min-height: 13vh;
    max-width: 17vw;
    max-height: 30vh;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000000a;
    border: 2.5px solid #6cb706;
    z-index: 3;
  }
  .Timeline-Card-Heading1 {
    text-align: left;
    font-size: 1.2vw;
    padding: 18px;
    letter-spacing: 0px;
    color: #006885;
    text-transform: uppercase;
  }
  .Timeline-Card-Heading2 {
    text-align: left;
    font-family: Avenir Next Bold;
    font-size: 1.8vw;
    padding: 1vw;
  }
  .Line-4-SVG {
    height: 60%;
    width: 100%;
    padding-bottom: 3vw;
  }
  .Line-3-4-Container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 36vw;
    height: 50vh;
    left: 45vw;
    top: 1vh;
    z-index: 2;
  }
  .Line-5-Container {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    width: 26vw;
    height: 30vh;
    left: 23vw;
    top: 50vh;
    overflow: hidden;
    z-index: 0;
  }
  .Line-5-SVG {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .Line-1-2-Container {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33vw;
    height: 55vh;
    left: 20vw;
    top: 9vh;
    overflow: hidden;
    z-index: 2;
  }
  .Reveal-Cards-Block {
    position: absolute;
    background-color: #f5fcfe;
    width: 0vw;
    height: 40vw;
    z-index: 3;
  }
}

/*=====  End of @Media Queries  ======*/
