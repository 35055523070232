/*=============================================
=           About Help Styles            =
=============================================*/

/*----------  Main Styles  ----------*/

/*=============================================
=            Main Containers            =
=============================================*/

.About-Help-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 70vh;
  width: 100%;
  max-width: 100vw;
  background-color: #006885;
  overflow: hidden;
}
.About-Help-Content-Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 27vw;
  width: 100%;
  position: relative;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Left Column            =
=============================================*/

.Help-Content-Left-Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 40%;
  height: 95%;
}
.Help-Heading {
  font-family: Avenir Next Bold;
  color: white;
  font-size: 47px;
  text-transform: uppercase;
}
.Help-Heading-Text {
  color: white;
  font-size: medium;
  line-height: 1.7em;
}

/*----------  Middle Bar Separator  ----------*/

.Help-Middle-Bar {
  display: flex;
  width: 3px;
  height: 200px;
  background-color: #b2d233;
}

/*=====  End of Left Column  ======*/

/*=============================================
=            Right Column           =
=============================================*/

.Help-Content-Right-Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 35%;
  height: 100%;
}
.Help-Right-Text {
  color: white;
  font-size: medium;
  line-height: 2.3em;
  list-style-type: none;
}

/*=====  End of Right Column  ======*/

/*=============================================
=            Ghost Drape Elements            =
=============================================*/

.About-Drape-Left {
  display: flex;
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #006885;
  left: -50vw;
  will-change: auto;
}
.About-Drape-Right {
  display: flex;
  position: absolute;
  width: 45%;
  height: 100%;
  background-color: #006885;
  right: -50vw;
}

/*=====  End of Ghost Drape Elements  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/
@media (max-width: 735px){
  .Help-Heading {
    font-size: 4.7vw;
  }
  .Help-Heading-Text {
    font-size: 2.4vw;
    line-height: 1.8em;
  }
  .Help-Right-Text {
    font-size: 2.4vw;
    line-height: 1.8em;
  }
  .Help-Content-Left-Container {
    height: 100%;
  }
}

@media (max-width: 500px) {
  .About-Help-Container {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 205vw;
    width: 100%;
    max-width: 100vw;
  }
  .About-Help-Content-Row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 90%;
    width: 100%;
    position: relative;
  }
  .Help-Middle-Bar {
    display: flex;
    width: 80%;
    height: 4px;
    right: 0;
    background-color: #b2d233;
    position: absolute;
    animation-name: barin;
    animation-duration: 1.4s;
  }
  .Help-Content-Left-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80%;
    height: 80%;
  }
  .Help-Heading {
    font-family: Avenir Next Bold;
    color: white;
    font-size: 10vw;
    text-transform: none;
  }
  .Help-Heading-Text {
    font-family: Avenir Book;
    color: white;
    font-size: 4vw;
    line-height: 1.96em;
  }
  .Help-Content-Right-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 80%;
    height: 100%;
  }
  .Help-Right-Text {
    font-family: Avenir Book;
    color: white;
    font-size: 4vw;
    line-height: 2em;
    list-style-type: none;
    width: 90%;
  }
}


/*=====  End of @Media Queries  ======*/
