

/*=============================================
=            Application Global Styles            =
=============================================*/

html {
  max-width: 100vw;
}
* {
  scroll-behavior: smooth;
  font-family: Avenir Next;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  --primary-color: #B2D233;
  --secondary-color: #006885;
  --accent-color: #00A8E5;
}
@font-face {
  font-family: Avenir Next;
  src: url('./app/assets/fonts/Avenir.ttf');
}
@font-face {
  font-family: Avenir Next Bold;
  src: url('./app/assets/fonts/Avenir-Bold.ttf');
}
@font-face {
  font-family: Michroma;
  src: url('./app/assets/fonts/Michroma-Regular.ttf');
}
.AppContainer {
  padding: 0;
  margin: 0;
  background-color: var(--secondary-color);
  height: 100vh;
  max-width: 100vw;
}
h1 {
  color: var(--primary-color);
  font-family: Michroma;
}
h2 {
  color: var(--accent-color);
  font-family: Michroma;
}
a {
  text-decoration: none;
  font-family: Michroma;
}
p {
  font-family: Michroma;
}
// Page Transition Animations
// .Fade-Transition-enter {
//   opacity: 0;
// }
// .Fade-Transition-enter-active {
//   opacity: 1;
//   transition: opacity 200ms;
// }
// .Fade-Transition-exit {
//   opacity: 1;
// }
// .Fade-Transition-exit-active {
//   opacity: 0;
//   transition: opacity 200ms;
// }