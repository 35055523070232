
/*=============================================
=            Home Page Styles            =
=============================================*/

/*=============================================
=            All Elements            =
=============================================*/
* {
  // border: .5px green dotted;
  margin: 0;
}
/*=====  End of All Elements  ======*/

/*=============================================
=            Home Page Container            =
=============================================*/
.Home-Container {
  display: inline;
  max-width: 100vw;
  height: fit-content;
  background-color: white;
  flex-direction: column;
  overflow-x: hidden;
}
/*=====  End of Home Page Container  ======*/

/*=============================================
=            SVG Grey Timeline            =
//** Grey line that borders the entry section
//** on the home page.
=============================================*/
.SVG-Time-Line {
  position: absolute;
  top: 200%;
  z-index: 25;
  background-color: transparent;
  width: 100%;
  max-width: 99.5vw;
  height: 150vh;
  will-change: auto;
}
.SVG-Time-Line[animatesvgline = '1'] {
  animation: slideRight 1.5s;
}
.Time-Line {
  overflow: hidden;
  fill: transparent;
  stroke-width: 4px;
  stroke: #B1B1B1;
}
/*=====  End of SVG Grey Timeline  ======*/


/*=============================================
=            Home Body Sections            =
// * Main Sections for the Home Page, these are
// * important for holding the badges in place.
=============================================*/
.Entry-Body-Container {
  display: flex;
  background-color: transparent;
  padding-bottom: 3vh;
}
.Career-Body-Container {
  display: flex;
  background-color: transparent;
  padding-bottom: 3vh;
}
.Advanced-Body-Container {
  display: flex;
  background-color: transparent;
  padding-bottom: 3vh;
}

/*=====  End of Home Body Sections  ======*/

/*=============================================
=            EV Pathways Highlight            =
=============================================*/
.Green-Highlight-Container {
  display: flex;
  position: absolute;
  background-color: transparent;
  margin-top: 70%;
  margin-left: 10%;
  width: 32%;
  flex-direction: row;
  animation: animateGreenHighlight 1s infinite;
  animation-direction: alternate-reverse;
}
@keyframes animateGreenHighlight {
  from {
    margin-left: 8vw;
  }
  to {
    margin-left: 10%;
  }
}
.Green-Highlight {
  z-index: 100;
  height: fit-content;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: #B2D233;
}
.Green-Header {
  width: 100%;
  position: relative;
  font-size: 30px;
  color: #006885;
  background-color: transparent;
  font-family: Michroma;
  font-weight: 800;
  padding: 8%;
  background-color: #B2D233;
  box-shadow: 1px 2px 5px #006885;
}
.Green-Paragraph {
  width: 100%;
  font-family: Michroma;
  font-size: 20px;
  color: white;
  padding: 8%;
  background-color: #006885;
  box-shadow: 1px 2px 5px #006885;
}
.Green-Hand {
  position: relative;
  background-color: transparent;
  width: 10vw;
  height: 10vw;
  width: 50%;
  transform: rotate(-90deg);
}
/*=====  End of EV Pathways Highlight  ======*/


/*=============================================
=            Home Page SVGs            =
=============================================*/
/*----------  Entry Green Horizontal Line  ----------*/
.SVG-Entry-Green {
  position: absolute;
  margin-top: 20vh;
  left: 25vw;
  z-index: 25;
  background-color: transparent;
  width: 10vw;
  height: 10vh;
  will-change: auto;
}
.SVG-Entry-Green[animatesvgline = '1'] {
  animation: slideRight 2s;
}
.Green-Line {
  overflow: hidden;
  fill: transparent;
  stroke-width: 4px;
  stroke: #B2D233;
}
/*----------  Career Dark Blue Vertical Line  ----------*/
.SVG-Career-Blue {
  position: absolute;
  left: 33vw;
  z-index: 25;
  background-color: transparent;
  width: 100%;
  max-width: 10vw;
  height: 15vh;
  margin-top: -16vh;
  will-change: auto;
}
.SVG-Career-Blue[animatesvgline = '1'] {
  animation: slideRight 1.5s;
}
.Dark-Blue-Line {
  overflow: hidden;
  fill: transparent;
  stroke-width: 4px;
  stroke: #006885;
}
/*----------  Advancement Light Blue Vertical Line ----------*/
.SVG-Advancement-Blue {
  position: absolute;
  left: 10vw;
  z-index: 25;
  background-color: transparent;
  width: 100%;
  max-width: 10vw;
  height: 13vh;
  max-height: 14vh;;
  margin-top: -6vh;
  will-change: auto;
}
.SVG-Advancement-Blue[animatesvgline = '1'] {
  animation: slideRightAdvancement 2s;
}
.Light-Blue-Line {
  overflow: hidden;
  fill: transparent;
  stroke-width: 4px;
  stroke: #00A8E5;
}
/*----------  Full Horizontal Dark Grey Line  ----------*/
.SVG-Full-Horizontal {
  position: absolute;
  padding: 0;
  right: 0;
  left: 0;
  width: 100%;
  // top: 460%;
  z-index: 25;
  background-color: transparent;
  max-width: 99.8vw;
}
.Full-Horizontal {
  fill: transparent;
  stroke-width: 4px;
  stroke: #707070;
}
/*----------  Jumbotron Footer Green Triangle  ----------*/
.Jumbotron-Triangle-SVG {
  top: 771.3%;
  z-index: 150;
  height: 20vh;
  width: 10vw;
  left: 0;
  position: absolute;
  background-color: transparent;
  margin: 0;
}
.Jumbotron-Triangle {
  fill: #B2D233;
  background-color: transparent;
}
/*=====  End of Home Page SVGs  ======*/

/*=============================================
=            Home Page Badges            =
=============================================*/
/*----------  Breaking the Industry Badge  ----------*/
.Breaking-Industry-Badge {
  display: flex;
  position: absolute;
  right: 6vw;
  padding-top: 10vh;
  overflow: hidden;
}
.Breaking-Industry-Image {
  width: 20vw;
  height: 20vw;
  font-family: Michroma;
  will-change: auto;
  overflow: hidden;
}
.Breaking-Industry-Image[rotatebadge = '1'] {
  animation-name: rotateBadge45;
  animation-duration: 1s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*----------  Entry Badge  ----------*/
.Entry-Badge {
  display: flex;
  position: absolute;
  overflow-x: hidden;
  right: 9vw;
  padding-top: 35vh;
  overflow: hidden;
}
.Entry-Badge-Image {
  font-family: Michroma;
  width: 10vw;
  height: 10vw;
  will-change: auto;
  overflow: hidden;
}
.Entry-Badge-Image[rotatebadge = '1'] {
  animation: rotateBadge45 1.5s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*----------  Career Badge  ----------*/
.Career-Badge {
  z-index: 30;
  display: flex;
  position: absolute;
  left: 9vw;
  padding-top: 12vh;
  will-change: transform auto;
  transform: rotate(-20deg);
  animation: rotateBadge45 1.5s;
}
.Career-Badge-Image {
  width: 15vw;
  height: 15vw;
  font-family: Michroma;
  will-change: auto;
}
.Career-Badge-Image[rotatebadge = '1'] {
  animation-name: rotateBadge45;
  animation-duration: 1s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*----------  Identifying A Career Pathway Badge  ----------*/
.Identifying-Badge {
  z-index: 25;
  display: flex;
  position: absolute;
  left: 14vw;
  margin-top: 30vh;
  padding: 0;
}
.Identifying-Badge-Image {
  font-family: Michroma;
  border-radius: 50%;
  width: 20vw;
  height: 20vw;
  box-shadow: 0px 0px 10px #00000029;
  will-change: auto;
}
.Identifying-Badge-Image[rotatebadge = '1'] {
  animation-name: rotateBadge45;
  animation-duration: 1s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*----------  Green Diamond Badge  ----------*/
.Green-Diamond-Badge {
  z-index: 30;
  display: flex;
  position: absolute;
  margin-top: 80vh;
  left: 20vw;
  animation: rotateBadge45 1.5s;
  will-change: auto;
}
.Green-Diamond-Badge-Image {
  width: 12vw;
  height: 12vw;
  will-change: auto;
}
.Green-Diamond-Badge-Image[rotatebadge = '1'] {
  animation-name: rotateBadge90;
  animation-duration: 1s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*----------  Blue Diamond Badge  ----------*/
.Blue-Diamond-Badge {
  z-index: 35;
  display: flex;
  position: absolute;
  margin-top: -5vh;
  right: 18vw;
  animation: rotateBadge45 1.5s;
  will-change: auto;
}
.Blue-Diamond-Image {
  width: 10vw;
  height: 10vw;
  will-change: auto;
}
.Blue-Diamond-Image[rotatebadge = '1'] {
  animation-name: rotateBadge90;
  animation-duration: 1s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*----------  Bench Badge  ----------*/
.Bench-Badge {
  z-index: 30;
  display: flex;
  position: absolute;
  margin-top: 5vh;
  right: 20vw;
}
.Bench-Image {
  width: 15vw;
  height: 15vw;
  will-change: auto;
}
.Bench-Image[rotatebadge = '1'] {
  animation-name: rotateBadge45;
  animation-duration: 1s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*----------  Green Arrow Badge  ----------*/
.Green-Arrow-Badge {
  z-index: 30;
  display: flex;
  position: absolute;
  margin-top: 40vh;
  right: 10vw;
  align-items: flex-end;
}
.Green-Arrow-Image {
  width: 7vw;
  height: 7vw;
  will-change: auto;
}
.Green-Arrow-Image[rotatebadge = '1'] {
  animation-name: rotateBadge90;
  animation-duration: 3s;
  transition: ease-in-out;
  -webkit-transform: ease-in-out;
  -moz-transform:  ease-in-out;
  -o-transform: ease-in-out;
  -ms-transform: ease-in-out;
}
/*=====  End of Home Page Badges  ======*/




/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes rotateBadge45 {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes rotateBadge90 {
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes slideRight {
  from {
    margin-left: -10%;
  }
  to {
    margin-left: 0%;
  }
}
@keyframes slideRightAdvancement {
  from {
    left: 20vw;
    background-color: transparent;
  }
  to {
    left: 10vw;
    background-color: transparent
  }
}
@keyframes slideLeft {
  from {
    right: 100%;
  }
  to {
    right: 0%;
  }
}


/*=====  End of @keyframe Animations  ======*/


/*=============================================
=            @media Queries            =
=============================================*/
/*----------  Width Based Queries  ----------*/
@media screen and (max-width: 1180px) { 
  .Home-Container {
    max-width: 100vw;
  }
  .Time-Line {
    display: none;
  }
  .Blue-Diamond-Badge {
    display: none;
  }
  .Blue-Diamond-Image {
    display: none;
  }
}
@media screen and (max-width: 1140px) {
  .Green-Highlight-Container {
    margin-top: 125%;
  }
}
@media screen and (max-width: 380px) { 
  .Entry-Badge {
    background-color: transparent;
  }
  .Entry-Badge-Image {
    width: 18vw;
    height: 18vw;
  }
  .Breaking-Industry-Badge {
    margin-top: 2vh;
  }
  .Breaking-Industry-Image {
    height: 25vw;
    width: 25vw;
  }
  .Career-Badge {
    margin-top: -10vh;
    left: 80%;
  }
  .Career-Badge-Image {
    height: 20vw;
    width: 20vw;
  }
  .Identifying-Badge {
    left: 60%;
  }
  .Identifying-Badge-Image {
    margin-top: 1vh;
    width: 25vw;
    height: 25vw;
  }
  .Green-Diamond-Badge {
    align-self: center;
    left: 80%;
    background-color: transparent;
  }
  .Green-Arrow-Badge {
    margin-top: 10vh;
    right: 10vw;
  }
  .Green-Arrow-Image {
    background-color: transparent;
    height: 20vw;
    width: 20vw;
  }
  .Dark-Blue-Line {
    display: none;
  }
  .Bench-Badge {
    margin-top: 15vh;
    right: 10vw;
  }
  .Blue-Diamond-Badge {
    margin-top: 3vh;
    right: 10vw;
  }
  .SVG-Full-Horizontal {
    display: flex;
    background-color: transparent;
    height: 5vh;
  }
  .SVG-Advancement-Blue {
    background-color: transparent;
  }
  .SVG-Entry-Green {
    background-color: transparent;
    left: 2vw;
    width: 5vw;
  }
  .SVG-Career-Blue {
    background-color: transparent;
    display: flex;
    z-index: 50;
  }
  .SVG-Career-Blue[animatesvgline = '1'] {
    animation: none;
  }
  .SVG-Time-Line {
    display: flex;
    margin-top: -15vh;
    margin-left: -40vw;
    background-color: transparent;
  }
  .Time-Line {
    display: flex;
    transform: scale(.5, .5);
  }
  .SVG-Time-Line[animatesvgline = '1'] {
    animation: none;
  }
  .Footer-Triangle-SVG {
    display: none;
  }
}
@media screen and (max-width: 780px) {
  .SVG-Entry-Green {
    display: none;
  }
  .SVG-Career-Blue {
    display: none;
  }
  .Footer-Triangle-SVG {
    display: flex;
    width: 30vw;
    transform: scaleY(.7);
  }
  .Career-Badge {
    left: 50%;
  }
  .Identifying-Badge {
    margin-top: 5vh;
    left: 70%;
  }
  .Green-Diamond-Badge {
    left: 80%;
    margin-top: 50vh;
  }
  .Green-Highlight-Container {
    display: none;
  }
  .Green-Carousel-Header {
    font-size: 3vw;
  }
}
@media screen and (max-width: 370px) { 
  .SVG-Time-Line {
    display: none;
  }
}

/*----------  Height and Width Based Queries  ----------*/
//** For IOS Screen height fluctuations because of bottom bar.
@media screen and (min-height: 810px) and (max-width: 400px) {
  .Entry-Badge-Image {
    width: 12vw;
    height: 12vw;
  }
  .Breaking-Industry-Badge {
    right: 10vw;
  }
  .Breaking-Industry-Image {
    height: 25vw;
    width: 25vw;
  }
  .Career-Badge {
    left: 50%;
  }
  .Career-Badge-Image {
    height: 20vw;
    width: 20vw;
  }
  .Identifying-Badge {
    left: 60%;
  }
  .Identifying-Badge-Image {
    width: 25vw;
    height: 25vw;
  }
  .Green-Diamond-Badge {
    margin-top: 10vh;
    left: 80%;
  }
  .Green-Arrow-Badge {
    margin-top: 35vh;
    background-color: transparent;
  }
  .Green-Arrow-Image {
    height: 20vw;
    width: 20vw;
  }
  .Dark-Blue-Line {
    display: none;
  }
  .Bench-Badge {
    margin-top: 12vh;
    right: 10vw;
  }
  .Blue-Diamond-Badge {
    margin-top: 0vh;
    right: 12vw;
  }
  .Blue-Diamond-Image {
    display: none;
  }
  .SVG-Full-Horizontal {
    display: flex;
    background-color: transparent;
    height: 5vh;
  }
  .SVG-Advancement-Blue {
    background-color: transparent;
  }
  .SVG-Entry-Green {
    background-color: transparent;
    left: 2vw;
    width: 5vw;
  }
  .SVG-Career-Blue {
    background-color: transparent;
    display: flex;
    z-index: 50;
  }
  .SVG-Career-Blue[animatesvgline = '1'] {
    animation: none;
  }
  .SVG-Time-Line {
    display: none;
  }
  .Time-Line {
    display: flex;
    transform: scale(.5, .5);
  }
  .SVG-Time-Line[animatesvgline = '1'] {
    animation: none;
  }
  .Footer-Triangle-SVG {
    display: none;
  }
}
/*=====  End of @media Queries  ======*/

