
/*=============================================
=            Pathway Page Styles            =
=============================================*/


/*=============================================
=            Pathway Page Container            =
=============================================*/
.Pathway-Page-Container {
  display: inline;
  height: fit-content;
  width: fit-content;
  max-width: 100vw;
}
/*=====  End of Pathway Page Container  ======*/



/*=============================================
=            Pathway Hero Container            =
=============================================*/
.Pathway-Hero-Container {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
}
/*=====  End of Pathway Hero Container  ======*/




