/*=============================================
=            EntryPage Styles            =
=============================================*/

/*----------  Linear Gradient Line  ----------*/

.Program-Bottom-Bar {
  display: flex;
  flex-direction: row;
  height: 2vh;
  width: 100%;
  max-width: 100vw;
  background: transparent linear-gradient(94deg, #90d12a 0%, #00a8e5 100%) 0% 0%
    no-repeat padding-box;
}
.Entry-Page-Container {
  display: inline;
  height: fit-content;
  width: fit-content;
  max-width: 100vw;
}

/*=====  End of EntryPage Styles  ======*/
