/*=============================================
=            AboutHero STYLES            =
=============================================*/

/*----------  Main Styling  ----------*/

/*=============================================
=            Main Containers            =
=============================================*/

.About-Hero-Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  position: relative;
  background-color: white;
  overflow: hidden;
}
.About-Hero-Background {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 95vh;
  background-color: #003b55;
  align-items: center;
  padding-left: 12vw;
}

/*=====  End of Main Containers  ======*/

/*=============================================================
=            Text and Heading Styles / Left Column            =
==============================================================*/

.About-Hero-Text-Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 42vw;
  height: 32vw;
}
.About-Hero-Heading-1 {
  color: #ffffff;
  font-weight: lighter;
  font-size: 26px;
}
.About-Hero-Heading-2 {
  color: #b2d233;
  font-family: Avenir Next Bold;
  font-size: 4vw;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
}
.About-Hero-Paragraph {
  color: #ffffff;
  font-size: medium;
  line-height: 35px;
}

/*=====  End of Text and Heading Styles  ======*/

/*===================================================
=            Image Styles / Right Column            =
===================================================*/

.About-Hero-Image-Row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100vw;
  height: 80vh;
  position: absolute;
  bottom: 0;
  will-change: transform, opacity;
}
.About-Hero-Image {
  display: flex;
  flex-direction: column;
  width: 35.7%;
  height: 80%;
  position: relative;
  background-position: center left 40%;
  background-size: cover;
}

/*=====  End of Image Styles  ======*/

/*=============================================
      =            Triangle            =
=============================================*/

.About-Hero-Triangle-Row {
  display: flex;
  flex-direction: row;
  position: absolute;
  justify-content: flex-end;
  height: 30vh;
  width: 100%;
  top: 15.3%;
  will-change: transform;
}
.About-Hero-Triangle {
  position: absolute;
  background-color: #b2d233;
  clip-path: polygon(40% 50%, 100% 0, 100% 100%);
  width: 150px;
  height: 200px;
  top: 3.1%;
}

/*=====  End of Triangle  ======*/

/*=============================================
=            Keyframe Animations            =
=============================================*/

@keyframes mobileinflaterow {
  from {
    -webkit-transform: scale(1, 0.4);
    -webkit-transform-origin: bottom;
    -moz-transform: scale(1, 0.4);
    -moz-transform-origin: bottom;
    -o-transform: scale(1, 0.4);
    -o-transform-origin: bottom;
    -ms-transform: scale(1, 0.4);
    -ms-transform-origin: bottom;
    transform: scale(1, 0.4);
    transform-origin: bottom;
    opacity: 0;
  }
  to {
    -webkit-transform: scale(1);
    -webkit-transform-origin: bottom;
    -moz-transform: scale(1);
    -moz-transform-origin: bottom;
    -o-transform: scale(1);
    -o-transform-origin: bottom;
    -ms-transform: scale(1);
    -ms-transform-origin: bottom;
    transform: scale(1);
    transform-origin: bottom;
    opacity: 1;
  }
}

/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/

@media (max-width: 500px) {
  .About-Hero-Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-width: 100vw;
  }
  .About-Hero-Background {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 90vh;
    background-color: #003b55;
    align-items: flex-start;
    padding-left: 10vw;
  }
  .About-Hero-Text-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80vw;
    height: 40vh;
    order: 1;
    padding-top: 110px;
  }
  .About-Hero-Heading-1 {
    font-size: 3vw;
  }
  .About-Hero-Heading-2 {
    font-size: 7vw;
    text-align: left;
    padding-right: 9vw;
  }
  .About-Hero-Paragraph {
    font-size: 3.7vw;
    line-height: 6.1vw;
  }
  .About-Hero-Image-Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100vw;
    height: 60vh;
    position: absolute;
    bottom: 0;
    order: 2;
  }
  .About-Hero-Image-Row[animatehero='1'] {
    animation: mobileinflaterow 0.9s;
  }
  .About-Hero-Image {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 67%;
    position: relative;
    background-position: center left 40%;
    background-size: cover;
  }
  .About-Hero-Triangle {
    position: absolute;
    background-color: #b2d233;
    clip-path: polygon(40% 50%, 100% 0, 100% 100%);
    width: 25vw;
    height: 18vh;
    top: 18%;
  }
}
@media only screen and (max-height: 800px) and (min-height: 800px) {
  .About-Hero-Background {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 90vh;
    background-color: #003b55;
    align-items: flex-start;
    padding-left: 10vw;
  }
  .About-Hero-Image {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100%;
    position: relative;
    background-position: center left 40%;
    background-size: cover;
  }
  .About-Hero-Container {
    height: 100vh;
  }
  .About-Hero-Triangle-Row {
    top: 46.8vh;
  }
  .About-Hero-Image-Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100vw;
    height: 45vh;
    position: absolute;
    bottom: 0vh;
    order: 2;
  }
   .About-Hero-Text-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80vw;
    height: 35vh;
    order: 1;
    padding-top: 115px;
  }
}

/*=====  End of @Media Queries  ======*/