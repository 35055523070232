
/*=============================================
=            App Logo Styles            =
=============================================*/


/*=============================================
=            App Logo Container            =
=============================================*/
.App-Logo-Container {
  display: flex;
  background-color: transparent;
  height: auto;
  align-items: center;
  width: auto;
  z-index: 20;
}
.App-Logo-Container:hover {
  color:#B2D233;
}
/*=====  End of App Logo Container  ======*/


/*=============================================
=            App Logo Texts            =
=============================================*/


.Logo-Link-Blue {
  display: flex;
  color: white;
  justify-content: space-between;
  background-color: transparent;
  flex-direction: row;
  width: 10vw;
}
.Logo-Link-White {
  display: flex;
  color: white;
  justify-content: space-between;
  background-color: transparent;
  flex-direction: row;
  width: 10vw;
}
.Logo-Link-Blue {
  display: flex;
  color: #003B55;
  justify-content: space-between;
  background-color: transparent;
  flex-direction: row;
  width: 10vw;
}
.Logo-Image {
  height: 50px;
  width: 40px;
}
.Logo-Text {
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: baseline;
  background-color: transparent;
}
.Logo-Bold-Text {
  display: flex;
  padding-right: 5px;
  font-weight: 600;
  align-items: center;
  justify-content: baseline;
  background-color: transparent;
  z-index: 10;
}
.Logo-Text-Blue {
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: baseline;
  background-color: transparent;
  z-index: 10;
}
.Logo-Bold-Text-Blue {
  padding-left: 5px;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: baseline;
  background-color: transparent;
}

/*=====  End of App Logo Texts  ======*/


/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 1150px) {
  .App-Logo-Container {
    padding-left: 3vw;
  }
}
@media screen and (max-width: 400px) {
  .Logo-Bold-Text {
    font-size: 3vw;
  }
  .Logo-Text {
    font-size: 3vw;
  }
}
/*=====  End of @media Queries  ======*/



