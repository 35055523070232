
/*=============================================
=            Circle Chart Styles            =
=============================================*/

/*=============================================
=            Circle Chart Container            =
=============================================*/
.Chart-Container {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
  grid-template-columns: auto;
  grid-template-rows: auto;
  will-change: auto;
}
.Chart-Container[animatechart = '1'] {
  animation: slideChart 1.5s;
}
.Chart-Container-Mobile {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
  grid-template-columns: auto;
  grid-template-rows: auto;
}


/*=====  End of Circle Chart Container  ======*/

/*=============================================
=            Chart            =
=============================================*/


.Chart {
  grid-row: 1;
  grid-column: 1;
  height: fit-content;
  max-width: 100vw;
  background-color: transparent;
}

/*=====  End of Chart  ======*/



/*=============================================
=            Centered Chart Header Text            =
=============================================*/
.Chart-Text {
  font-family: Avenir Next Bold;
  color: #004C5E;
  text-align: center;
  justify-self: center;
  grid-row: 1;
  grid-column: 1;
  width: fit-content;
  font-size: 3vw;
  width: 15vw;
  background-color: transparent;
}
/*=====  End of Centered Chart Header Text  ======*/


/*=============================================
=            Chart Disclaimer            =
=============================================*/
#Chart-Disclaimer {
  background-color: transparent;
  width: 100%;
  height: 5vw;
  height: fit-content;
  font-family: Michroma;
  display: flex;
  justify-content: center;
  font-size: 0.8vw;
}

/*=====  End of Chart Disclaimer  ======*/


/*=============================================
=            Chart Components            =
=============================================*/

/*----------  Chart SVG  ----------*/
.Circle-Path:hover {
  animation-name: hoverPath;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes hoverPath {
  from {
    fill-opacity: 1;
  }
  to {
    stroke-width: 10px;
    fill-opacity: .8;
  }
}

#Chart-SVG {
  background-color: transparent;
  max-width: 100%;
}
/*----------  Chart SVG  ----------*/

#Chart-Label {
  font-family: Avenir Next Bold;
  text-overflow: ellipsis;
  display: flex;
}
/*----------  Chart SVG  ----------*/

#Chart-Label-Text {
  font-family: Avenir Next Bold;
  font-size: 1vw;
  font-size-adjust: auto;
}
/*----------  Chart Sub Label  ----------*/
//  * Not Currently in Use *
// #Chart-Sub-Label {
//   display: flex;
//   margin-top: 10px;
//   position: relative;
//   font-family: Avenir Next;
// }
/*=====  End of Chart Components  ======*/



/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes slideChart {
  from {
    width: 20%;
    max-height: 100%;
  }
  to {
    width: 100%;
    max-height: 100%;
  }
}
/*=====  End of @keyframe Animations  ======*/


/*=============================================
=            @media Queries            =
=============================================*/

@media screen and (max-width: 1000px) {
  .Chart-Container-Mobile {
    height: 50vh;
    display: flex;
    background-color: transparent;
    padding-top: 0;
  }
  .Chart-Container {
    display: none;
  }
  .Chart-Text {
    display: flex;
    position: absolute;
    align-self: center;
    justify-self: center;
  }

  #Chart-Label-Text {
    font-size: 1.3vw;
    align-self: center;
    word-wrap: break-word;
    text-transform: uppercase;
  }
}
/*=====  End of @media Queries  ======*/


