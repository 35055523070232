/*========================================================================
              =            ProgramHero Styles            =
=========================================================================*/

/*----------  Main Styling  ----------*/

/*=============================================
=            Main Containers            =
=============================================*/

.Program-Hero-Background {
  display: flex;
  flex-direction: row;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  background-size: cover;
  overflow: hidden;
}
.Program-Hero-Container {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  opacity: 1;
  background-color: rgba(0, 61, 87, 0.8);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

/*----------  Chrome Specific Main Containers  ----------*/

.Chrome-Program-Hero-Background {
  display: flex;
  flex-direction: row;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  background-size: cover;
  overflow: hidden;
}
.Chrome-Program-Hero-Container {
  height: 100vh;
  width: 100%;
  opacity: 1;
  max-width: 100vw;
  background-color: rgba(0, 61, 87, 0.8);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Badges            =
=============================================*/

/*----------  Main Badge Containers  ----------*/

.Program-Badge-Container {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 93vw;
  height: 345px;
  right: 14vw;
  top: 300px;
  justify-content: flex-end;
  align-items: flex-end;
  will-change: auto;
}

/*----------  Entry Page Badges  ----------*/

.Program-Industry-Badge-01 {
  width: 220px;
  height: 220px;
  padding-right: 3vw;
  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
  animation: rotateIndustryBadge 1.7s;
  will-change: transform auto;
}
.Program-Program-Badge-01 {
  width: 18vw;
  height: 18vh;
  position: absolute;
  top: 34vh;
  right: 1vw;
  will-change: transform auto;
  animation: rotateEntryBadge 1.7s;
}

/*----------  Career Page Badges  ----------*/

.Program-Industry-Badge-02 {
  width: 170px;
  height: 170px;
  padding-right: 2vw;
  will-change: transform auto;
  animation: rotateIdentifyingBadge 1.6s;
}
.Program-Program-Badge-02 {
  width: 160px;
  height: 170px;
  position: absolute;
  top: 80px;
  right: 100px;
  will-change: transform auto;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  animation: rotateCareerBadge 1.6s;
}

/*----------  Advancement Page Badges  ----------*/

.Program-Industry-Badge-03 {
  width: 220px;
  height: 220px;
  padding-right: 0vw;
  will-change: transform auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  animation: rotateBenchBadge 1.6s;
}
.Program-Program-Badge-03 {
  width: 12vw;
  height: 17vh;
  position: absolute;
  top: 10vh;
  right: -5vw;
  will-change: auto;
  animation: rotateAdvanceBadge 1.6s;
}

/*----------  EV-Career Page Badges  ----------*/
.Program-Badge-EV-Container {
  height: 165px;
  width: 204px;
  position: absolute;
  top: 75px;
  right: 5vw;
}
.Program-Badge-EV {
  height: 100%;
  width: 100%;
  position: relative;
  will-change: transform auto;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
  animation: rotateCareerBadge 1.6s;
}

/*=====  End of Badges  ======*/

/*=============================================
=            Triangle Image            =
=============================================*/

.Transparent-Triangle {
  width: 23vw;
  height: 100vh;
  position: absolute;
  background-size: cover;
  background-position: 75% top;
  background-repeat: no-repeat;
  clip-path: polygon(100% 50%, 0 0%, 0% 100%);
}

/*=====  End of Triangle Image  ======*/

/*=============================================
=            Titles           =
=============================================*/

/*----------  Entry / Main Titles  ----------*/

.Entry-Title-Container {
  height: 97vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  opacity: 1;
  position: absolute;
  padding-top: 0.9vh;
  padding-left: 0;
  width: 60vw;
  overflow: hidden;
  will-change: transform auto;
}
.Entry-Hero-Title {
  font-family: Avenir Next Bold;
  font-size: 8.5vw;
  text-transform: uppercase;
}


/*----------  Career Titles  ----------*/

.Career-Title-Container {
  height: 97vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  opacity: 1;
  position: absolute;
  padding-top: 0.9vh;
  padding-left: 1vw;
  width: 60vw;
  will-change: transform auto;
}
.Career-Hero-Title {
  font-family: Avenir Next Bold;
  font-size: 7vw;
  text-transform: uppercase;
}
.EV-Career-Hero-Title {
  font-family: Avenir Next Bold;
  font-size: 6vw;
  text-transform: uppercase;
  margin-left: 0.7vw;
}

/*----------  Advancement Titles  ----------*/

.Advancement-Title-Container {
  height: 97vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 67vw;
  align-items: center;
  opacity: 1;
  position: absolute;
  padding-top: 0.9vh;
  overflow: hidden;
  will-change: transform auto;
}
.Advancement-Hero-Title {
  font-family: Avenir Next Bold;
  font-size: 5.7vw;
}

/*=====  End of Titles  ======*/

/*=============================================
=            Program Pages' Line           =
=============================================*/

/*----------  Chrome / Main Specific Zone  ----------*/

.Program-Line-Zone-Chrome {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 29%;
}

/*----------  Firefox Specific Zone  ----------*/

.Program-Line-Zone-Firefox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
}

/*----------  SVG Line Style  ----------*/

.Program-Line {
  stroke: #ffffff;
  stroke-width: 3.5px;
  fill: black;
  border: 5px solid #ffffff;
}

/*=====  End of Line for Chrome and Firefox  ======*/

/*=============================================
=            Hero Paragraph / Discover Section            =
=============================================*/

.Discover-Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  height: 550px;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  padding-bottom: 1vw;
  padding-right: 10vw;
  align-self: flex-end;
}
.Discover-Content-Container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 500px;
  height: 300px;
  max-height: 300px;
  position: absolute;
  overflow: hidden;
  will-change: auto;
}
.Discover-Title {
  font-size: x-large;
  color: white;
  font-family: Avenir Next Bold;
  text-transform: uppercase;
}
.Discover-Paragraph {
  font-size: medium;
  color: white;
  line-height: 2em;
  letter-spacing: 1px;
}

/*----------  Start Browsing Button  ----------*/

.SB-Button {
  font-size: large;
  padding-left: 12vw;
  word-spacing: 0.3vw;
  will-change: auto;
}

/*=====  End of Hero Text / Discover Section  ======*/

/*=============================================
=            Keyframe Animations           =
=============================================*/

@keyframes rotateIndustryBadge {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(50deg);
    -moz-transform: rotate(50deg);
    -o-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    opacity: 1;
  }
}
@keyframes rotateIdentifyingBadge {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(-50deg);
    -moz-transform: rotate(-50deg);
    -o-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes rotateBenchBadge {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateEntryBadge {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(-30deg);
    -moz-transform: rotate(-30deg);
    -o-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes rotateCareerBadge {
  0% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(50deg);
    -moz-transform: rotate(50deg);
    -o-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
    opacity: 1;
  }
}
@keyframes rotateAdvanceBadge {
  0% {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes slideRevealButton {
  from {
    -webkit-transform: translateX(-10vw);
    -moz-transform: translateX(-10vw);
    -o-transform: translateX(-10vw);
    -ms-transform: translateX(-10vw);
    transform: translateX(-10vw);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideinentrytitle {
  from {
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
    transform: translateX(-300px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slidediscover {
  from {
    -webkit-transform: translateX(6vw);
    -moz-transform: translateX(6vw);
    -o-transform: translateX(6vw);
    -ms-transform: translateX(6vw);
    transform: translateX(6vw);
  }
  to {
    -webkit-transform: translateX(0vw);
    -moz-transform: translateX(0vw);
    -o-transform: translateX(0vw);
    -ms-transform: translateX(0vw);
    transform: translateX(0vw);
  }
}
/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/
@media (min-width: 1850px){
  .Advancement-Title-Container {
  height: 97vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 64vw;
  align-items: center;
  opacity: 1;
  position: absolute;
  padding-top: 0.9vh;
  padding-left: 7vw;
  overflow: hidden;
  will-change: transform auto;
}
.Program-Line-Zone-Chrome {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26%;
}
.Program-Line-Zone-Firefox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
}
}
@media (min-width: 1470px) {
  .Program-Line-Zone-Firefox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    padding-left: 3vw;
  }
}
@media (max-width: 1000px) {
  .Discover-Container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1vw;
  }
  .Discover-Content-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 58vw;
    height: 360px;
    max-height: 360px;
    position: absolute;
    overflow: hidden;
    will-change: auto;
  }
  .Discover-Title {
    font-size: x-large;
    color: white;
    font-family: Avenir Next Bold;
    text-transform: uppercase;
  }
  .Discover-Paragraph {
    font-size: medium;
    color: white;
    line-height: 2em;
    letter-spacing: 1px;
    font-family: Avenir Book;
  }
  .SB-Button {
    font-size: large;
    padding-left: 18vw;
    word-spacing: 0.3vw;
  }
  .Career-Title-Container {
    width: 56vw;
  }
}
@media (max-width: 700px) {
  .Discover-Paragraph {
    font-size: 3.8vw;
  }
}
@media (max-width: 600px) {
  .Program-Hero-Background {
    background-size: cover;
    background-position: 70% 60%;
    height: 100vh;
  }
  .Program-Hero-Container {
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
  }
  .Transparent-Triangle {
    width: 100vw;
    height: 30vh;
    position: relative;
    background-size: cover;
    background-position: bottom right;
    clip-path: polygon(0% 100%, 0 60%, 50% 0%, 100% 60%, 100% 100%);
    order: 4;
    overflow: hidden;
    bottom: 0;
    align-self: flex-end;
  }
  .Entry-Title-Container {
    height: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    opacity: 1;
    position: relative;
    padding-top: 0.9vh;
    order: 2;
    width: 52vw;
    padding-left: 0;
  }
  .Entry-Title-Container[animatehero='1'] {
    animation: none;
  }
  .Career-Title-Container {
    height: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    opacity: 1;
    position: relative;
    padding-top: 0.9vh;
    order: 2;
    width: 50vw ;
    padding-left: 10vw;
  }
  .Career-Title-Container[animatehero='1'] {
    animation: none;
  }
  .Advancement-Title-Container {
    height: 45vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    opacity: 1;
    position: relative;
    padding-top: 0.9vh;
    order: 2;
    width: 88vw;
    padding-left: 6vw;
  }
  .Entry-Hero-Title {
    font-size: 12vw;
  }
  .Career-Hero-Title {
    font-size: 12vw;
  }
  .Advancement-Hero-Title {
    font-size: 8.7vw;
  }
  .Program-Line-Zone-Chrome {
    display: none;
  }
  .Program-Line-Zone-Firefox {
    display: none;
  }
  .Program-Badge-Container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 95vw;
    height: 90vw;
    right: 0vw;
    justify-content: flex-end;
    align-items: center;
    order: 1;
    padding-bottom: 4vw;
  }
  .Program-Industry-Badge-01 {
    position: absolute;
    width: 140px;
    height: 140px;
    padding-right: 0vw;
    will-change: transform auto;
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    animation: rotateIndustryBadge 1.7s;
    top: 25vw;
    right: 12vw;
  }
  .Program-Industry-Badge-02 {
    position: absolute;
    width: 140px;
    height: 140px;
    padding-right: 0vw;
    will-change: transform auto;
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    animation: none;
    top: 20vw;
    right: 10vw;
  }
  .Program-Badge-EV-Container {
    height: 130px;
    width: 170px;
    position: absolute;
    top: 12vh;
    right: 28vw;
  }
  .Program-Industry-Badge-03 {
    position: absolute;
    width: 140px;
    height: 140px;
    padding-right: 0vw;
    will-change: auto;
    animation: rotateBenchBadge 1.4s;
    top: 29vw;
    right: 16vw;
  }
  .Program-Program-Badge-01 {
    width: 80px;
    height: 17vh;
    position: relative;
    display: flex;
    top: 23vw;
    right: 12vw;
    animation: none;
  }
  .Program-Program-Badge-02 {
    width: 90px;
    height: 17vh;
    position: relative;
    display: flex;
    top: 22vw;
    right: 9vw;
    animation: rotateCareerBadge 1.7s;
  }
  .Program-Program-Badge-03 {
    width: 80px;
    height: 17vh;
    position: relative;
    display: flex;
    top: 15vw;
    right: 6vw;
    animation: none;
  }
  .Discover-Container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100vw;
    position: relative;
    justify-content: center;
    align-items: center;
    padding-bottom: 1vw;
    overflow: hidden;
    order: 2;
    padding-right: 0;
  }
  .Discover-Content-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 65%;
    max-width: 65vw;
    height: 40vw;
    max-height: 45vw;
    position: relative;
    left: 0;
  }
  .Discover-Content-Container[animatehero='1'] {
    animation: none;
  }
  .Discover-Title {
    font-size: 4vw;
    color: white;
    font-family: Avenir Next Bold;
  }
  .Discover-Paragraph {
    font-size: 2.6vw;
    color: white;
    line-height: 1.5em;
    letter-spacing: 0.9px;
    font-family: Avenir Book;
  }
  .SB-Button {
    font-size: 3vw;
    padding-left: 20vw;
    width: 50vw;
    word-spacing: 0.8vw;
  }
  .SB-Button[animatehero='1'] {
    animation: none;
  }
}
@media (max-width: 420px) {
  .Program-Hero-Background {
    height: 100vh;
    min-height: 100vw;
    width: 100%;
    max-width: 100vw;
  }
  .Program-Hero-Container {
    height: 100vh;
    width: 100vw;
  }
  .Transparent-Triangle {
    width: 100vw;
    height: 80vw;
  }
  .Discover-Container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 70vw;
    align-items: center;
    justify-content: center;
    padding-bottom: 1vw;
    order: 3;
  }
  .Discover-Content-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 75vw;
    height: 50vw;
    max-height: 45vw;
    left: 0;
    position: relative;
    overflow: hidden;
  }
  .Discover-Content-Container[animatehero='1'] {
    animation: none;
  }
  .Discover-Title {
    font-size: 4.2vw;
    color: white;
    font-family: Avenir Next Bold;
  }
  .Discover-Paragraph {
    font-size: 3vw;
    color: white;
    line-height: 1.5em;
    letter-spacing: 0.9px;
    font-family: Avenir Book;
  }
  .SB-Button {
    font-size: 3vw;
    padding-left: 10vw;
    width: 50vw;
    word-spacing: 0.8vw;
  }
  .Entry-Hero-Title {
    font-family: Avenir Next Bold;
    font-size: 13vw ;
  }
  .EV-Career-Hero-Title {
    font-size: 9.5vw;
  }
  .Entry-Title-Container {
    height: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    opacity: 1;
    position: relative;
    padding-top: 0.9vh;
    order: 2;
    width: 75vw ;
    padding-left: 8vw;
  }
  .Career-Title-Container {
    height: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    opacity: 1;
    position: relative;
    padding-top: 0.9vw;
    padding-left: 9vw;
    order: 2;
    width: 75vw;
  }
  .Program-Industry-Badge-01 {
    position: absolute;
    width: 130px;
    height: 130px;
    padding-right: 0vw;
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    animation: rotateIndustryBadge 1.7s;
    top: 22.5vw;
    right: 18vw;
  }
  .Program-Industry-Badge-02 {
    position: absolute;
    width: 120px;
    height: 120px;
    padding-right: 0vw;
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    -o-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
    animation: rotateIndustryBadge 1.7s;
    top: 20vw;
    right: 5vw;
  }
  .Program-Badge-EV-Container {
    height: 120px;
    width: 150px;
    position: absolute;
    top: 12vh;
    right: 25vw;
  }
  .Program-Industry-Badge-03 {
    position: absolute;
    width: 125px;
    height: 125px;
    padding-right: 0vw;
    animation: rotateBenchBadge 1.4s;
    top: 25vw;
    right: 12vw;
  }
  .Program-Program-Badge-01 {
    width: 70px;
    height: 17vh;
    top: 20vw;
    right: 8vw;
  }
  .Program-Program-Badge-02 {
    width: 80px;
    height: 17vh;
    position: relative;
    display: flex;
    top: 12vw;
    right: 30vw;
    animation: none;
  }
  .Program-Program-Badge-03 {
    width: 60px;
    height: 17vh;
    position: relative;
    display: flex;
    top: 2vw;
    right: 5vw;
    animation: none;
    -webkit-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
    -o-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  .Advancement-Title-Container {
    height: 12vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 1;
    position: relative;
    padding-top: 0.9vw;
    order: 2;
    width: 90% ;
    padding-left: 2vw;
  }
}
@media screen and (width: 360px) and (height: 880px) {
  .Program-Hero-Container {
    height: 100vh;
  }
}
/*=====  End of @Media Queries  ======*/
