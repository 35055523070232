/*=============================================
=            AboutStory Styles            =
=============================================*/

/*----------  Main Styles  ----------*/

/*=============================================
=            Main Containers            =
=============================================*/

.About-Story-Container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70vh;
  max-width: 100vw;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  overflow: hidden;
  background-color: white;
}

/*=====  End of Main Containers  ======*/

/*=============================================
=            Text / Paragraphs            =
=============================================*/

.About-Story-Paragraph-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 52vw;
  height: 25vw;
  will-change: auto;
}
.About-Story-Paragraph {
  color: #707070;
  line-height: 2em;
  font-size: medium;
}

/*=====  End of Text / Paragraphs  ======*/

/*=============================================
=            Badges            =
=============================================*/

.About-Story-Badge-Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 30vw;
  width: 15vw;
  position: relative;
}
.About-Story-Badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 120px;
  height: 120px;
  z-index: 5;
  position: absolute;
  right: -4vw;
  will-change: auto;
}
.About-Story-Badge-SVG {
  width: 100%;
  height: 100%;
  transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
}

/*=====  End of Badges  ======*/

/*=============================================
=            Keyframe Animations            =
=============================================*/

@keyframes mobileslideinrotateright {
  0% {
    right: -50vw;
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
  50% {
    right: 5vw;
    transform: rotate(180);
    -webkit-transform: rotate(180);
    -moz-transform: rotate(180);
    -o-transform: rotate(180);
    -ms-transform: rotate(180);
  }
  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}

/*=====  End of Keyframe Animations  ======*/

/*=============================================
=            @Media Queries            =
=============================================*/

// @media (max-width: 700px) {
//   .About-Story-Paragraph {
//   font-size: 3.8vw;
// }
// }
@media (max-width: 700px) {
  .About-Story-Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    max-width: 100vw;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    overflow: hidden;
  }
  .About-Story-Paragraph-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    height: auto;
  }
  .About-Story-Paragraph {
    font-family: Avenir Book;
    color: #707070;
    line-height: 1.9em;
    font-size: 3.8vw;
  }
  .About-Story-Badge-Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 20vw;
    width: 100vw;
    position: relative;
  }
  .About-Story-Badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100px;
    height: 103px;
    z-index: 5;
    position: absolute;
    right: 10vw;
    will-change: auto;
  }
  .About-Story-Badge[animatestory='1'] {
    animation-name: mobileslideinrotateright;
    animation-duration: 2s;
  }
}

/*=====  End of @Media Queries  ======*/
