
/*=============================================
=            Navigation Styles            =
=============================================*/

/*=============================================
=            Navigator Containers            =
=============================================*/
.Navigator {
  top: 4vh;
  left: 0;
  z-index: 150;
  position: absolute;
  column-gap: 2vw;
  display: flex;
  width: 100%;
  max-width: 100vw;
  flex-direction: row;
  justify-content: space-around;
}
.Navigator-White {
  color: black;
  height: 15vh;
  column-gap: 3vw;
  top: 0vh;
  left: 0;  
  z-index: 150;
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 100vw;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: white;
}
/*=====  End of Navigator Containers  ======*/


/*=============================================
=    Navigation Horizontal Menu Bars          =
=============================================*/
.Navigator-Bar {
  padding-left: 8vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 45vw;
  background-color: transparent;
  will-change: auto;
  // animation-name: openNavBar;
  // animation-duration: .5s;
}
.Navigator-Bar[opennavbar = '1'] {
  animation-name: openNavBar;
  animation-duration: .7s;
}
.Navigator-Bar-White {
  padding-left: 11vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 45vw;
  background-color: transparent;
  z-index: 10;
  will-change: auto;
  // animation-name: openNavBar;
  // animation-duration: .5s;
}
.Navigator-Bar-White[opennavbar = '1'] {
  animation-name: openNavBar;
  animation-duration: .7s;
}
/*=====  End of Navigation Horizontal Menu Bars  ======*/


/*=============================================
=            Navigation Links            =
=============================================*/

.Navigator-Link {
  color: white;
  display: flex;
  align-items: center;
  justify-content: baseline;
  background-color: transparent;
  font: 14px Avenir Next;
}
.Navigator-Link:visited {
  color: white;
}
.Navigator-Link:hover {
  color: #B2D233;
}
.Navigator-Link-Black {
  color: #333333;
  display: flex;
  border: none;
  align-items: center;
  justify-content: baseline;
  background-color: transparent;
  font: 14px Avenir Next;
}
/*=====  End of Navigation Links  ======*/


/*=============================================
=            Navigator Dropdowns           =
=============================================*/
.Navigator-Dropdown {
  color: white;
  font: 16px Avenir Next;
  background-color: transparent;
  border: none;
  width: 6.2em;
  cursor: pointer;
}
.Navigator-Dropdown-Black {
  color: #333333;
  font: 16px Avenir Next;
  background-color: transparent;
  border: none;
  width: 6.2em;
  cursor: pointer;
}
.Navigator-Dropdown:hover {
  color: #B2D233;
}
.Dropdown-Option {
  color: #006885;
  font-size: 80%;
  font-weight: 500;
}
/*=====  End of Navigator Dropdowns  ======*/


/*=============================================
=            Apply Button            =
=============================================*/


.IMEC-Button {
  padding-left: 20px;
  padding-right: 20px;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #B2D233;
  color: #B2D233;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.IMEC-Button:hover {
  background-color: #B2D233;
  border: 1px solid white;
  color: white;
}
.IMEC-Button-Blue {
  margin: 2vw;
  padding-left: 25px;
  padding-right: 25px;
  font-family: Michroma;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid #006885;
  color: #006885;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.IMEC-Button-Blue:hover {
  border: 1px solid #B2D233;
  color: #B2D233;
}


/*=====  End of Apply Button  ======*/


/*=============================================
=            Mobile Menu           =
= Most of the Styles for this are in
= the At Media Query section below.
=============================================*/
.Mobile-Menu-Container-Open {
  display: none;
}
.Mobile-Menu-Container-Closed {
  display: none;
}
/*=====  End of Mobile Menu ======*/

/*=============================================
=            @media Queries            =
=============================================*/
/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 1150px) {
  .Navigator {
    display: flex;
    background-color: transparent;
    justify-content: space-between;
  }
  .Navigator-White {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Navigator-Bar {
    display: none;
  }
  .Navigator-Bar-White {
    display: none;
  }
  .IMEC-Button {
    display: none;
  }
  .IMEC-Button-Blue {
    display: none;
  }
  .Mobile-Menu-Container-Closed {
    display: flex;
    position: relative;
    background-color: transparent;
    justify-self: flex-end;
    align-items: center;
    justify-content: center;    
    right: 5vw;
  }
  .Mobile-Menu-Container-Open {
    display: flex;
    position: fixed;
    flex-direction: column;
    background-color: transparent;
    top: 0;
    right: 0vw;
    background-color: #006885f1;
    width: 45vw;
    height: 100vh;
    z-index: 50;
  }
  .Hamburger-Button-Container {
    align-self: flex-start;
    width: 100%;
    justify-content: flex-end;
  }
  .Hamburger-Button {
    display: flex;
    width: fit-content;
    padding: 3vw;
    cursor: pointer;
  }
  .Close-Button {
    display: flex;
    position: absolute;
    width: fit-content;
    background-color: transparent;
    padding: 3vw;
    cursor: pointer;
    justify-content: flex-end;
  }
  .Mobile-Menu-List {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }
  .Mobile-Menu-Link {
    font-family: Avenir Next Bold;
    text-transform: uppercase;
    font-size: 1em;
    color:#B2D233;
    width: fit-content;
  }
  .Mobile-Menu-Link:hover {
    color: white;
  }
  .Mobile-Menu-Link-White {
    font-family: Avenir Next Bold;
    text-transform: uppercase;
    font-size: 1em;
    color:white;
    width: fit-content;
  }
  .Mobile-Menu-Link-White:hover {
    color: #B8EA60;
    text-shadow: 0 1px 1px #006885;
  }
 }
/*=====  End of @media Queries  ======*/



 /*=============================================
 =            @keyframe Animations            =
 =============================================*/
@keyframes openNavBar {
  0% {
    transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}
 /*=====  End of @keyframe Animations  ======*/
 
 