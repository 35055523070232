
/*=============================================
=            Providers Styles            =
=============================================*/


/*=============================================
=            Providers Container            =
=============================================*/

.Providers-Container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: white;
  width: 100%;
  max-width: 100vw;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
  padding-bottom: 20vh;
}

/*=====  End of Providers Container  ======*/


/*=============================================
=            Providers Header            =
=============================================*/
.Providers-Header-Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 25%;
  margin-bottom: 10vh;
}
.Providers-Header {
  height: 20%;
  width: 45%;
  display: flex;
  flex-direction: row;
  color: #B2D233;
  font-family: Avenir Next Bold;
  font-size: 4vw;
}
/*=====  End of Providers Header  ======*/



/*=============================================
=            Providers Summary            =
=============================================*/
.Providers-Summary {
  width: 35%;
  line-height: 2em;
  font-family: Avenir Next;
  color: #707070;
}


/*=====  End of Providers Summary  ======*/



/*=============================================
=            Providers Regional Grid            =
=============================================*/
.Providers-List {
  display: grid;
  justify-items: center;
  width: 100%;
  grid-template-columns: 50% 50%;
  list-style: none;
}
/*=====  End of Providers Regional Grid  ======*/



/*=============================================
=            Providers Grid List Item            =
=============================================*/
.Providers-List-Item-Link {
  color: #00A8E5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  border-bottom: 1px #70707070 solid;
  width: 100%;
  padding-bottom: 2em;
  padding-top: 1.5em;
  max-width: 30vw;
}
.Provider-List-Item-Link:visited {
  color: #00A8E5;
}
.Providers-List-Item {
  color: #707070;
  font-weight: 700;
}
.Providers-List-Item:hover {
  color: #00A8E5;
}


/*=====  End of Providers Grid List Item  ======*/




/*=============================================
=            Regions            =
=============================================*/
.Region {
  background-color: transparent;
  width: 40%;
  padding: 5vw;
}
.Region:hover {
  border: 1px solid #00A8E5;
  border-radius: 0.5%;
}
.Region-Title {
  font-family: Michroma;
  color: #00A8E5;
}

/*=====  End of Regions  ======*/


/*=============================================
=            Provider Disclaimer           =
=============================================*/
.Providers-Disclaimer {
  font-family: Avenir Next;
  color: #707070;
  font-size: 0.8vw;
  width: 30%;
  height: 5vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*=====  End of Provider Disclaimer  ======*/




/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 600px) {
  .Providers-Container {
    display: flex;
    background-color: transparent;
  }
  .Providers-Header {
    font-size: 5vw;
  }
  .Providers-Header-Container {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20vw;
    justify-content: space-evenly;
    height: 50vh;
  }
  .Providers-Summary {
    width: 70%;
  }
  .Providers-List {
    grid-template-columns: auto;
  }
  .Providers-List-Item-Link {
    width: 100%;
    background-color: transparent;
  }
  .Providers-Disclaimer {
    padding-top: 10vh;
    font-size: 3vw;
  }
}

/*=====  End of @media Queries  ======*/



