
/*=============================================
=            SVG Triangle Styles            =
=============================================*/


/*=============================================
=            Home Hero SVG            =
=============================================*/
.Home-Hero-SVG {
  top: 75.3vh;
  height: 50vh;
  width: 15vh;
  right: 0%;
  position: absolute;
  overflow-x: hidden;
  background-color: transparent;
  z-index: 50;
}
.Home-Hero-Triangle {
  overflow: hidden;
  fill: #B2D233;
  will-change: auto;
  background-color: transparent;
  animation-name: slideTriangleLeft;
  animation-duration: 1s;
  animation-direction: normal;
  transition: transform ease-out 0.3s;
  -webkit-transform: transform ease-out 0.3s;
  -moz-transform: transform ease-out 0.3s;
  -o-transform: transform ease-out 0.3s;
  -ms-transform: transform ease-out 0.3s;
}


/*=====  End of Home Hero SVG  ======*/



/*=============================================
=            Footer SVG Triangle            =
=============================================*/
.Footer-Triangle-SVG {
  left: 0;
  z-index: 100;
  width: auto;
  height: auto;
  position: absolute;
}
.Footer-Triangle {
  fill: #B2D233;
  will-change: auto;
  animation-name: slideTriangleRight;
  animation-duration: 1s;
}

/*=====  End of Footer SVG Triangle  ======*/


/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes slideTriangleLeft {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slideTriangleRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}
/*=====  End of @keyframe Animations  ======*/


/*=============================================
=            Media Queries            =
=============================================*/

@media (max-width: 768px) {
  .Home-Hero-SVG {
    top: 75.3vh;
    height: 50vh;
    width: 15vh;
    right: 0%;
    position: absolute;
    overflow-x: hidden;
    background-color: transparent;
    z-index: 50;
  }
  .Home-Hero-Triangle {
    display: none;
  }
}

/*=====  End of Media Queries  ======*/



