
/*=============================================
=   Overview Section For Pathway Page         =
=============================================*/


/*=============================================
=            Overview Container            =
=============================================*/
.Overview-Container {
  background-color: transparent;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-top: 15vh;
  overflow: hidden;
  will-change: auto;
}
.Overview-Container[animateoverview = '1'] {
  animation: slideOverview 1.5s;
}
/*=====  End of Overview Container  ======*/


/*=============================================
=            Overview Title Header            =
=============================================*/
.Overview-Header-Container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  height: fit-content;
  width: 45%;
  padding-top: 10vh;
  padding-left: 16vw;
  background: transparent;
  line-height: 10vh;
}
.Overview-Title {
  color: #006885;
  font-size: 1.5vw;
  font-family: Michroma;
  text-transform: uppercase;
}
.Overview-Sub-Title {
  color: #B2D233;
  font-family: Avenir Next Bold;
  font-size: 4vw;
}
/*=====  End of Overview Title Header  ======*/


/*=============================================
=            Overview Summary            =
=============================================*/
.Overview-Summary {
  color: #707070;
  font-size: 1.3vw;
}
/*=====  End of Overview Summary  ======*/


/*=============================================
=            Overview Timeline Container            =
=============================================*/
.Overview-Timeline-Container {
  display: flex;
  padding-left: 22vw;
  padding-top: 10vh;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  align-self: center;
  justify-content: space-evenly;
  height: 50vh;
  list-style-type: none;
}
/*=====  End of Overview Timeline Container  ======*/


/*=============================================
=            Overview Timeline Node            =
=============================================*/

/*----------  Overview Timeline Node Container  ----------*/
.Overview-Timeline-Node {
  padding: 0vw;
  width: 40vw;
  height: fit-content;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  column-gap: 2vw;
  margin: 0;
  border: none;
  line-height: 2.5em;
}

/*----------  Overview Node Left Column  ----------*/
.Overview-Node-Left {
  display: flex;
  background-color: transparent;
  height: 10vh;
  flex-direction: column;
}
/*----------  Overview Node Right Column  ----------*/
.Overview-Node-Right {
  display: flex;
  background-color: transparent;
  height: fit-content;
  flex-direction: column;
}
/*----------  Timeline Node Header  ----------*/
.Timeline-Node-Header {
  margin-top: 0;
  padding-top: 0;
  font-family: Avenir Next Bold;
  color: #006885;
  font-size: 2vw;
}

/*----------  Timeline Node Information  ----------*/
.Timeline-Node-Info {
  color: #707070;
}


/**
 *
 * Overview Timeline Node before that makes
 the bullets before each node in the timeline
 function.
 * TODO: Fix Header for Mobile Screens
 * TODO: Fix Bullets For All Screens, They Need Polishing
 */


/*----------  Before Node Line  ----------*/
.Overview-Timeline-Node:before {
  z-index: 0;
  background-color: #707070;
  width: 2px;
  height: 10vh;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0;
  z-index: -10;
}
/*----------  Before Node Line  ----------*/
.Overview-Timeline-Node:first-child:before { top: 1vh; height: 15vh; z-index: 0;  }

/*----------  Last Item in The List Line  ----------*/
.Overview-Timeline-Node:last-child:before { top: -12vh; height: 8vw; z-index: 0; }

/*----------  Bullet  ----------*/
.Bullet { 
  display: flex;
  margin-left: -1.2vw;
  width: 2.5vw; 
  height: 2.5vw;
  fill: #003B55; 
  float: none;
  z-index: 200;
}
.Bullet-SVG {
  background-color: transparent;
  height: 3vw;
  width: 3vw;
  z-index: 150;
  z-index: 200;
}
.Bullet-Circle {
  z-index: 200;
  background-color: transparent;
}
/*=====  End of Overview Timeline Node  ======*/



/*=============================================
=            @keyframe Animations            =
=============================================*/
@keyframes slideOverview {
  from {
    width: 60%;
    max-height: 100%;
  }
  to {
    width: 100%;
    max-height: 100%;
  }
}
/*=====  End of @keyframe Animations  ======*/



/*=============================================
=            @media Queries            =
=============================================*/
@media screen and (max-width: 1200px) {
  .Overview-Container {
    background-color: transparent;
    align-items: center;
    justify-content: space-evenly;
    height: fit-content;
    padding-top: 10vh;
  };
  .Overview-Header-Container {
    font-size: 6vw;
    background-color: transparent;
    height: fit-content;
    line-height: 4vh;
    padding-left: 0;
  }
  .Overview-Title {
    font-size: 3vw;
    border: none;
    height: fit-content;
  }
  .Overview-Sub-Title {
    font-size: 5vw;
    border: none;
    height: fit-content;
    justify-content: flex-start;
  }
  .Bullet {
    background-color: transparent;
    height: 5vh;
    width: 5vh;
    margin-top: 5vh;
    margin-left: -2.5vh;
  }
  .Bullet-SVG {
    clip-path: ellipse(100% 100% at 45% 45%);
    background-color: transparent;
    width: fit-content;
    height: 100%;
  }
  .Timeline-Node-Header {
    font-size: 100%;
    width: 45vw;
    padding-left: 1vh;
    background-color: transparent;
    height: fit-content;
  }
  .Timeline-Node-Info {
    font-size: 80%;
    width: 45vw;
    padding-left: 1vh;
    background-color: transparent;
    height: 5vh;
  }
  .Overview-Timeline-Node {
    background-color: transparent;
    width: 100%;
    background-color: transparent;
    height: fit-content;
    column-gap: 0vw;
  }
  .Overview-Timeline-Node:before {
    display: none;
  }
}
/*=====  End of @media Queries  ======*/



